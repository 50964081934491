import { currentUser } from '@core/store/user/user.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, take } from 'rxjs';
import { Permission, PermissionName, Profile } from '@modules/login/login.interface';
import { Sidebar } from '@components/sidebar/sidebar.interface';
import { sidebarConfig } from '@constants/configurations';
import { ChannelPA, PermissionList, permissionsGroup } from '@constants/constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  loadingSession$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private store: Store) {
    this.store.select(currentUser).subscribe(el => (this.user = el));
  }

  user: Partial<Profile>;

  getUserProfileObservable() {
    return this.store.select(currentUser);
  }
  getUserProfile() {
    return this.user;
  }
  setUserProfile(user: Partial<Profile>) {
    this.user = user;
  }

  destroyLoading() {
    this.loadingSession$.unsubscribe();
  }

  getPermission() {
    if (this.user.permissions?.length) {
      return this.user.permissions;
    } else {
      return this.user.profile?.permissions?.map(el => el.permission);
    }
  }

  hasLumineaPermission(): boolean {
    return !!this.getPermission()?.some(permission => permission.id === 67);
  }

  filterLinkByPermission(ids: number[], sidebarLinks: Sidebar[]) {
    let permissionList = PermissionList;
    let permGroup = permissionsGroup;
    let channelsPA = ChannelPA;
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.VENDITA_COMMODITY_BUNDLE) ? link.url !== 'sales' : true));
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.KPI_MONITORING_DASHBOARD_BOE) ? link.url !== 'kpi-boe-monitoring' : true));
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.VISUALIZZAZIONE_FORMAZIONE) ? link.url !== 'training' : true));
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.POST_SALES) ? link.url !== 'post-sales' : true));
    sidebarLinks = sidebarLinks.filter(link => (!permGroup['dispatchingCall'].some(perm => ids?.includes(perm)) ? link.url !== 'boe' : true));
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.AGENCY_SELL) ? link.url !== 'agencies-manager' : true));
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.CAMPAGNE_ASSEGNATE) ? link.url !== 'assigned-campaign' : true));
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.TRACKING_AGENTE) ? link.url !== 'tracking-contracts' : true));
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.GESTIONE_LEAD) ? link.url !== 'lead-management' : true));
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.PREWINBACK) ? link.url !== 'prewinback-conf' : true));
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.VISUALIZZAZIONE_PRATICHE_LUMINEA) ? link.url !== 'tracking-contracts-luminea' : true));
    sidebarLinks = sidebarLinks.filter(link => (!permGroup['salesUtility'].some(perm => ids?.includes(perm)) ? link.url !== 'sales' : true));
    sidebarLinks = sidebarLinks.filter(link => (!permGroup['salesUtility'].some(perm => ids?.includes(perm)) ? link.url !== 'flat-offers' : true));
    sidebarLinks = sidebarLinks.filter(link => (!permGroup['catalog'].some(perm => ids?.includes(perm)) && !permGroup['script'].some(perm => ids?.includes(perm)) ? link.url !== 'catalog' : true));
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.CARICAMENTO_PRATICHE_MASSIVE) && !ids?.includes(permissionList.SCARTI_FUNZIONALI) && !ids?.includes(permissionList.SCARTI_TECNICI) ? link.url !== 'massive-practices' : true));
    sidebarLinks = sidebarLinks.filter(link => (!permGroup['kpiMonitoringTab'].some(perm => ids?.includes(perm)) ? link.url !== 'kpi-monitoring' : true));
    sidebarLinks = sidebarLinks.filter(link => (!ids?.includes(permissionList.INSTANT_CALL) || !(ids?.includes(permissionList.INSTANT_CALL) && this.user.profile?.id === 1 && this.user?.agency?.canale === channelsPA.AGENZIA_D2D) ? link.url !== 'instant-call' : true));
    sidebarLinks = sidebarLinks.filter(link =>
      !ids?.includes(permissionList.CREAZIONE_CREDENZIALI) && !ids?.includes(permissionList.MODIFICA_CREDENZIALI) && !ids?.includes(permissionList.GESTIONE_VISIBILITA_PERMESSI) && !ids?.includes(permissionList.BLACKLIST) ? link.url !== 'agent-profile' : true
    );
    sidebarLinks = sidebarLinks.filter(link => (!permGroup['recensioni'].some(perm => ids?.includes(perm)) ? link.url !== 'recensioni' : true));
    return sidebarLinks;
  }
}
