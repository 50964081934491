import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { SelectInputType } from '@modules/wizard-sales/store/sales.state';
import { ModalComponent } from '@shared/modal/modal.component';
import { map } from 'rxjs';
import { Modal, PermissionFE } from './interfaces';

export enum SalesRoutes {
  PREVENDITA = 'pre-vendita',
  INITIALCHECK = 'initialCheck',
  PRECHECK = 'pre-check',
  ESITO = 'esito',
  CONTRATTUALIZZAZIONE = 'contrattualizzazione',
  COMMODITY = 'commodity',
  VAS = 'vas',
  RACCOLTA_DATI = 'raccolta-dati',
  RIEPILOGO = 'riepilogo',
  INVIO = 'invio'
}

export enum SalesID {
  INITIALCHECK = 'initialCheck',
  PRECHECK = 'pre-check',
  ESITO = 'esito',
  CONTRATTUALIZZAZIONE = 'contrattualizzazione',
  COMMODITY = 'commodity',
  VAS = 'vas',
  RACCOLTA_DATI = 'raccoltaDati',
  RIEPILOGO = 'riepilogo',
  INVIO = 'invioo'
}

export enum SalesCommercialActionsBE {
  VOLTURA_III = 'VOLTURA_VOLTURANTE',
  VOLTURA_IV = 'VOLTURA_4',
  CAMBIO_OFFERTA = 'CAMBIO_OFFERTA',
  CAMBIO_MERCATO = 'CAMBIO_MERCATO',
  PREWIN_BACK = 'PREWIN_BACK',
  VOLTURA_SWITCH = 'VOLTURA_SWITCH',
  SWITCHIN = 'SWITCHIN',
  VOLTURA = 'Voltura',
  SWITCHINDOC = 'SWITCH IN',
  SUBENTRO = 'SUBENTRO',
  SUBENTRO_DEL40 = 'Subentro Gas Delibera 40', // Chiedere/Verificare a BE cosa scende dal precheck
  ALLACCIO_ATTIVAZIONE = 'ALLACCIO_ATTIVAZIONE',
  ATTIVAZIONE = 'ATTIVAZIONE'
}

export enum PertinenzeOffers {
  IEAUBE2S = 'IEAUBE2S',
  IEAUBE2V = 'IEAUBE2V'
}

export enum DualMono {
  DUAL = 'DUAL',
  MONO = 'MONO'
}

export enum SalesCommercialActions {
  ALLACCIO_CON_ATTIVAZIONE = 'Allaccio con ATT',
  ATTIVAZIONE_DUAL = 'Attivazione DUAL',
  ATTIVAZIONE_ELE = 'Attivazione Ele',
  ATTIVAZIONE_GAS = 'Attivazione Gas',
  ATTIVAZIONE_GAS_DELIBERA = 'Attivazione Gas delibera 40',
  CAMBIO_MERCATO = 'Cambio Mercato',
  CAMBIO_OFFERTA = 'Cambio Offerta',
  PREWIN_BACK = 'Pre-Win Back',
  SUBENTRO_DUAL = 'Subentro DUAL',
  SUBENTRO_ELE = 'Subentro Ele',
  SUBENTRO_GAS = 'Subentro Gas',
  SUBENTRO_DEL40_GAS = 'Subentro Gas Delibera 40',
  SWITCH_IN = 'Switch In',
  SWITCH_IN_DUAL = 'Switch IN Dual',
  SWITCH_IN_SCHEDULATO = 'Switch In Schedulato',
  VOLTURA = 'Voltura', // corrisponde sia a voltura III che voltura IV
  VOLTURA_SWITCHING_ELE = 'Voltura con Switching Ele',
  VOLTURA_DUAL = 'Voltura DUAL' // corrisponde sia a voltura III che voltura IV
}

export const CaricaDocVisibilityStates = ['in attesa caricamento verbal order', 'in attesa call', 'in attesa verifica dati', 'attesa conferma plico digitale iren', 'attesa stampa proposta', 'attesa consegna plico a cliente', 'in attesa firma'];
export const CaricaDocLumineaVisibilityStates = ['in attesa caricamento verbal order'];

export enum TrackingSalesKeys {
  SELLING_TYPE = 'Tipo di Vendita',
  COMMODITY = 'Commodity',
  NAME = 'Nome',
  SURNAME = 'Cognome',
  POD = 'Pod',
  PDR = 'Pdr',
  SOCIAL_REASON = 'Ragione Sociale',
  CF = 'Codice Fiscale',
  PIVA = 'P.Iva',
  COD_ELE = 'Codice Ele',
  COD_ENEL_TEL_GAS = 'Codice Enel Gas',
  ADDRESS_FORN = 'Indirizzo Fornitura',
  RESIDENCE_ADDRESS = 'Indirizzo di Residenza',
  DELIVERY_ADDRESS = 'Indirizzo di Consegna',
  FORNITURE_ADDRESS = 'Indirizzo Fornitura',
  INSTALLATION_ADDRESS = 'Indirizzo Installazione',
  MAINTENANCE_YEAR = 'Anno di installazione',
  OPERATION = 'Operazione',
  STIPULATION_METHOD = 'Metodo di stipula',
  SIGN_TYPE = 'Tipologia di firma',
  MOTIVAZIONE_KO_QUOTE = 'Motivazione Ko Quote',
  NOTE = 'Note',
  MOBILE_PHONE = 'Cellulare',
  EMAIL = 'Email',
  ESITO_CAUSALE = 'Esito Causale',
  COMMODITY_OFFER = 'Offerta Commodity',
  VAS_OFFER = 'Offerta Vas',
  CONTRACTUALIZED_POWER = 'Potere contrattuale',
  PROFILE_WITHDRAWAL = 'Profilo di Prelievo',
  YEARLY_CONSUMPTION_ELE = 'Consumo Annuo Ele',
  YEARLY_CONSUMPTION_GAS = 'Consumo annuo Gas',
  PAYMENT_METHOD = 'Metodo di Pagamento',
  BILLING_FORMAT = 'Formato di Fatturazione',
  BANK_NAME = 'Nome Banca',
  IBAN = 'Iban',
  BIC_SWIFT = 'Codice Bic/Swift',
  BILLING_TYPE = 'Tipo di Fatturazione',
  FILE_TYPE = 'Tipo File',
  OPERATOR_CODE = 'Codice operatore',
  AGENCY_CODE = 'Codice agenzia',
  SPORTELLO = 'Sportello',
  WORKING_GROUP = 'Gruppo di lavoro',
  RANDOM_CODE = 'Random Code',
  SUPPLY_POINT = 'Punto di fornitura',
  OWNER = 'Titolare',
  DAYS_SPENT = 'Giorni Trascorsi',
  ONGOING_PRACTICE_STATE = 'Stato avanzamento SFDC',
  CANCELLATION_CAUSE = 'Causale annullamento',
  OWNER_CF_PIVA = 'CF/P.IVA Titolare',
  MOTIVAZIONE_PIVA = 'Motivazione IVA',
  OVER_85 = 'Cliente over 85 in whitelist',
  ACCEPTANCE_QUOTE_DATE = 'Data di accettazione',
  CANCELLATION_DATE = 'Data di annullamento',
  REQUEST_DATE = 'Data di sottoscrizione',
  OPPORTUNITY_ID = 'ID Opportunity',
  QUOTE_ID = 'ID Quote',
  SUPPLY_ADDRESS = 'Indirizzo di fornitura',
  SIGNED_PROPOSAL_FILE_NAME = 'Nome file di proposta firmata',
  OFFER_NUMBER = 'Nome offerta',
  USER_NUMBER = 'Numero utente',
  QUOTE = 'Quote',
  SKU_CODE = 'Sku code',
  PROCESS_IN_PROGRESS = 'Tipo lavorazione',
  CALL_TYPE = 'Tipologia call',
  DOCUMENTATION_RESEND = 'Reinvio documentazione',
  MARKET_TYPE = 'Tipo Mercato',
  VOCAL_ORDER_ACCEPTANCE = 'Accettazione vocal order',
  VOCAL_ORDER_SUBSCRIPTION = 'Sottoscrizione vocal order',
  PRACTICE_STATE = 'Stato avanzamento IF',
  ACCEPTANCE_DATE = 'Data accettazione cliente',
  ACCEPTANCE_PHONE = 'Numero di telefono accettazione',
  PRODUCT_VAS_NAME = 'Nome prodotto',
  FATT_CONG = 'Fatturazione congiunta',
  INSTALLMENTS_NUMBER = 'Numero rate',
  DETAIL_COMMERCIAL_VARIATION = 'Dettaglio variazione commerciale',
  TYPE_COMMERCIAL_VARIATION = 'Tipologia variazione commerciale',
  VULNERABILITY = 'Vulnerabilità'
}

export enum OngoingPracticeState {
  ACTIVE = 'attivo',
  VOID = 'Annullato',
  ACCEPTED = 'accettato',
  WAITING_FOR_DOCUMENTATION = 'In attesa di documentazione',
  MODULES_COMPLETION = 'Completamento modulistica',
  PLICO_ACCEPTANCE = 'Accettazione Plico digitale IREN',
  DIGITAL_SIGNATURE_KO = 'KO firma digitale IREN',
  EXPIRED_DIGITAL_SIGNATURE = 'Tempi di firma digitale IREN scaduti',
  DRAFT = 'Draft'
}

export enum PostSalesWorkingTypes {
  ACTIVATED = 'Attivati',
  CANCELLED = 'Annullati',
  DIGITAL_SIGNATURE_ACCEPTANCE = 'Accettazione firma digitale',
  DIGITAL_SIGNATURE_KO = 'KO Firma digitale IREN',
  DIGITAL_SIGNATURE_TIMING = 'Tempi di firma digitale IREN scaduti',
  MODULES_COMPLETION = 'Completamento modulistica'
}
export enum PostSalesWorkingTypesTwo {
  REGISTRY_VARIATION = 'Variazione anagrafica',
  COMMERCIAL_VARIATION = 'Variazione commerciale',
  PAYMENT_VARIATION = 'Variazione metodo di pagamento',
  CONSENT_VARIATION = 'Variazione consensi'
}

export enum ModalStatus {
  WARNING = 'Warning', // usato nei servizi non bloccanti
  ERROR = 'Error', // errore api
  SUCCESS = 'Success', // successo api
  GENERIC = 'Generic'
}

export enum ModalCtaTypes {
  PROCEED_OR_CANCEL = 'proceedOrCancel',
  PROCEED = 'proceed',
  BACK_OR_CANCEL = 'backOrCancel',
  CONFIRM_OR_CANCEL = 'confirmOrCancel',
  CLOSE = 'close',
  YES_NO = 'yesOrNo',
  GO_TO_HOMEPAGE = 'goToHomepage'
}

export enum StatoAvanzamentoValue {
  COMPLETE = 'In Attesa Completamento Luminea',
  DRAFT = 'Attesa assegnazione Luminea'
}
export enum StatoAvanzamentoViewValue {
  COMPLETE = 'In Attesa Completamento Luminea',
  DRAFT = 'Attesa assegnazione Luminea'
}
export enum StatoAvanzamentoDescription {
  COMPLETE = 'Pratiche Luminea  da assegnare ad agente abilitato a Luminea ',
  DRAFT = 'Pratiche già assegnate da completare con la parte VAS'
}

export enum RCVasSubtype {
  AUTO = 'AUTO',
  MOTO = 'MOTO',
  FURGONE = 'FURGONE'
}

export enum RCVasSubtypeVertiId {
  AUTO = 'AUTO',
  MOTO = 'MOTO',
  FURGONE = 'FURGONE'
}

export enum StatoAvanzamentoRcValue {
  COMPLETE = 'In Attesa Completamento Luminea RC',
  DRAFT = 'Attesa assegnazione Luminea RC',
  WASTED = 'In attesa risoluzione scarto RC Auto'
}
export enum StatoAvanzamentoRcViewValue {
  COMPLETE = 'In Attesa Completamento Luminea Rc',
  DRAFT = 'Attesa assegnazione Luminea Rc',
  WASTED = 'In attesa risoluzione scarto RC Auto'
}
export enum StatoAvanzamentoRcDescription {
  COMPLETE = 'Pratiche Luminea Rc da assegnare ad agente abilitato a Luminea Rc',
  DRAFT = 'Pratiche già assegnate da completare con la parte VAS',
  WASTED = 'In attesa risoluzione scarto RC Auto da parte di Verti'
}

export enum PermissionList {
  CATALOG_BUNDLE = 1,
  UTILITY_COMMODITY_BUNDLE = 2,
  UTILITY_VAS = 3,
  VENDITA_COMMODITY_BUNDLE = 4,
  VENDITA_VAS = 5,
  POST_SALES = 6,
  TRACKING_AGENZIA = 7,
  TRACKING_AGENTE = 8,
  CARICAMENTO_MODULISTICA = 9,
  KPI_MONITORING_AGENZIA = 10,
  KPI_MONITORING_AGENTE = 11,
  CREAZIONE_CREDENZIALI = 12,
  MODIFICA_CREDENZIALI = 13,
  GESTIONE_VISIBILITA_PERMESSI = 14,
  BLACKLIST = 15,
  VISUALIZZAZIONE_FORMAZIONE = 16,
  GESTIONE_CONTENUTI_FORMATIVI = 17,
  PROFILO = 18,
  SCARTI_FUNZIONALI = 19,
  SCARTI_TECNICI = 20,
  GESTIONE_LEAD = 21,
  GESTIONE_REGOLE_DISPATCHING = 22,
  CAMPAGNE_ASSEGNATE = 23,
  CATALOG_COMMODITY = 24,
  CATALOG_VAS = 25,
  CARICAMENTO_PRATICHE_MASSIVE = 26,
  SCRIPT_TAB = 27,
  SCRIPT_EDIT = 28,
  DAILY_TASK = 29,
  DAILY_TASK_ASSIGN = 30,
  CREAZIONE_CAMPAGNE = 31,
  CONFIGURAZIONE_GARE = 34,
  UPLOAD_CSV_LEAD_FREDDI = 36,
  CARICAMENTO_ESITI_RPO = 37,
  INSTANT_CALL = 38,
  ASSEGNAZIONE_LEAD_AGENZIE = 39,
  KPI_LEAD_CALDI = 40,
  DOWNLOAD_LEAD_CALDI = 41,
  UPLOAD_ESITI_LEAD_CALDI = 42,
  KPI_LEAD_FREDDI = 43,
  DOWNLOAD_LEAD_FREDDI = 44,
  UPLOAD_ESITI_LEAD_FREDDI = 45,
  DISPONIBILITA_LEAD_FREDDI_AGENZIA = 46,
  DISPONIBILITA_LEAD_FREDDI_IREN = 47,
  KPI_MONITORING_DASHBOARD_BOE = 48,
  KPI_MONITORING_DASHBOARD_SALES = 49,
  KPI_MONITORING_DASHBOARD_AZ_COMM = 50,
  KPI_MONITORING_DASHBOARD_FOCUS = 51,
  KPI_MONITORING_DASHBOARD_GEO = 52,
  KPI_MONITORING_DASHBOARD_REPORT = 53,
  AGENCY_SELL = 55,
  FUB_RPO = 56,
  PREWINBACK = 57,
  DETTAGLIO_LAVORAZIONE_LEAD = 58,
  DETTAGLIO_CONTRATTI = 59,
  SOCIAL_ADS_P = 60,
  GOOGLE_ADS_P = 61,
  WEB_SERVICE_P = 62,
  CRM_P = 63,
  ESTRAZIONE_ESITI = 64,
  VENDITA_LUMINEA = 67,
  ASSEGNAZIONE_LUMINEA = 68,
  VISUALIZZAZIONE_PRATICHE_LUMINEA = 69,
  KPI_LEAD_TARGHE = 70,
  ENRICHMENT_LEAD_TARGHE = 71,
  DOWNLOAD_LEAD_TARGHE = 72,
  UPLOAD_ESITI_LEAD_TARGHE = 73,
  BOE_DISPATCHING = 74,
  BOE_REDISPATCHING = 75,
  GESTIONE_VISIBILITA_PERMESSI_OPERATORE = 76,
  REDISPATCHING_LEAD_TARGHE = 77,
  ESTRAZIONE_ESITI_LEAD_TARGHE = 78,
  TRUSTPILOT_SEND_INVITATION = 79,
  TRUSTPILOT_SET_TEMPLATE = 80,
  TRUSTPILOT_KPI = 81,
  TRUSTPILOT_REPORT = 82,
  TRUSTPILOT_SET_GOALS = 83,
  TRUSTPILOT_SET_AGENCY_PERMISSION = 84,
  WHITELIST_OVER_85 = 85,
  PRIORITY_TRACKING = 86,
  TRACKING_BOE_SALES = 87,
  TRACKING_FIRMA_DIGITALE = 88,
  ESTRATTO_CONTO = 89
}

const hotKpiLead = [
  PermissionList.DOWNLOAD_LEAD_CALDI,
  PermissionList.UPLOAD_ESITI_LEAD_CALDI,
  PermissionList.DETTAGLIO_LAVORAZIONE_LEAD,
  PermissionList.DETTAGLIO_CONTRATTI,
  PermissionList.SOCIAL_ADS_P,
  PermissionList.GOOGLE_ADS_P,
  PermissionList.WEB_SERVICE_P,
  PermissionList.CRM_P,
  PermissionList.ESTRAZIONE_ESITI
];

const coldKpiLead = [PermissionList.DOWNLOAD_LEAD_FREDDI, PermissionList.UPLOAD_ESITI_LEAD_FREDDI, PermissionList.KPI_LEAD_FREDDI, PermissionList.CARICAMENTO_ESITI_RPO];

const plateKpiLead = [PermissionList.KPI_LEAD_TARGHE, PermissionList.ENRICHMENT_LEAD_TARGHE, PermissionList.DOWNLOAD_LEAD_TARGHE, PermissionList.UPLOAD_ESITI_LEAD_TARGHE, PermissionList.REDISPATCHING_LEAD_TARGHE, PermissionList.ESTRAZIONE_ESITI_LEAD_TARGHE];

export const permissionsGroup: PermissionFE = {
  massivePractices: [PermissionList.CARICAMENTO_PRATICHE_MASSIVE, PermissionList.SCARTI_FUNZIONALI, PermissionList.SCARTI_TECNICI],
  catalog: [PermissionList.CATALOG_COMMODITY, PermissionList.CATALOG_BUNDLE, PermissionList.CATALOG_VAS],
  script: [PermissionList.SCRIPT_TAB, PermissionList.SCRIPT_EDIT],
  salesUtility: [PermissionList.UTILITY_COMMODITY_BUNDLE, PermissionList.UTILITY_VAS],
  salesVendita: [PermissionList.VENDITA_COMMODITY_BUNDLE, PermissionList.VENDITA_VAS],
  agentLanding: [PermissionList.CREAZIONE_CREDENZIALI, PermissionList.BLACKLIST, PermissionList.MODIFICA_CREDENZIALI, PermissionList.GESTIONE_VISIBILITA_PERMESSI],
  agentActiveInactive: [PermissionList.CREAZIONE_CREDENZIALI, PermissionList.MODIFICA_CREDENZIALI],
  kpiMonitoringTab: [
    PermissionList.KPI_MONITORING_DASHBOARD_BOE,
    PermissionList.KPI_MONITORING_DASHBOARD_SALES,
    PermissionList.KPI_MONITORING_DASHBOARD_AZ_COMM,
    PermissionList.KPI_MONITORING_DASHBOARD_FOCUS,
    PermissionList.KPI_MONITORING_DASHBOARD_GEO,
    PermissionList.KPI_MONITORING_DASHBOARD_REPORT
  ],
  availability: [PermissionList.DISPONIBILITA_LEAD_FREDDI_AGENZIA, PermissionList.DISPONIBILITA_LEAD_FREDDI_IREN],
  tracking: [PermissionList.TRACKING_AGENTE, PermissionList.TRACKING_AGENZIA, PermissionList.TRACKING_FIRMA_DIGITALE],
  hotKpiLead,
  coldKpiLead,
  plateKpiLead,
  leadDispatching: [PermissionList.ASSEGNAZIONE_LEAD_AGENZIE, PermissionList.GESTIONE_REGOLE_DISPATCHING, ...hotKpiLead, ...coldKpiLead, ...plateKpiLead],
  dispatchingCall: [PermissionList.BOE_DISPATCHING, PermissionList.BOE_REDISPATCHING],
  practicesManagement: [PermissionList.PRIORITY_TRACKING],
  recensioni: [PermissionList.TRUSTPILOT_SEND_INVITATION, PermissionList.TRUSTPILOT_SET_TEMPLATE, PermissionList.TRUSTPILOT_KPI, PermissionList.TRUSTPILOT_REPORT],
  customerManagement: [PermissionList.WHITELIST_OVER_85]
};

export const commercialOperationXPermission = [
  {
    opComm: 'VOLTURA_VOLTURANTE',
    copy: 'Voltura'
  },
  {
    opComm: 'VOLTURA_4',
    copy: 'Voltura Titolo IV'
  },
  {
    opComm: 'CAMBIO_OFFERTA',
    copy: 'Cambio offerta'
  },
  {
    opComm: 'CAMBIO_MERCATO',
    copy: 'Cambio mercato'
  },
  {
    opComm: 'PREWIN_BACK',
    copy: 'Prewin Back'
  },
  {
    opComm: 'VOLTURA_SWITCH',
    copy: 'Voltura con switch'
  },
  {
    opComm: 'SWITCHIN',
    copy: 'Switch In'
  },
  {
    opComm: 'SUBENTRO',
    copy: 'Subentro'
  },
  {
    opComm: 'Subentro Gas Delibera 40',
    copy: 'Subentro delibera 40'
  },
  {
    opComm: 'ALLACCIO_ATTIVAZIONE',
    copy: 'Allaccio con attivazione'
  },
  {
    opComm: 'ATTIVAZIONE',
    copy: 'Attivazione'
  }
];

export const PERSONA_FISICA_ACCOUNT_TYPE = 'Persona fisica';
export const CONDOMINI_ACCOUNT_TYPE = 'Condominio';
export const PERSONA_GIURIDICA_ACCOUNT_TYPE = 'Persona giuridica';
export const DITTA_INDIVIDUALE_ACCOUNT_TYPE = 'Ditta individuale';
export const PUBBLICA_AMMINISTRAZIONE_ACCOUNT_TYPE = 'Pubblica Amministrazione';

export const DocType = ["CARTA IDENTITA'", 'PATENTE DI GUIDA', 'PASSAPORTO', 'LIBRETTO PENSIONE', "PORTO D'ARMI", 'TESSERA MINISTERI', 'PERMESSO DI SOGGIORNO'];

export const AuthGroup = ['1920 - Atena', '9915 - SEV', '9000 - Iren Mercato', '9940 - Alegas'];

export const ClusterWeb = ['Affiliation', 'Aiuto Self', 'Aiuto_Self_Sem', 'Altro', 'Chat', 'Cluster web', 'Google Ads', 'Portale Comparatore', 'Progetti Speciali', 'Recupero Self', 'Self', 'Sem', 'Social Media', 'Web'];
export const ClusterOffer = ['Standard', 'OLD', 'PFIX2'];
export const ClusterAction = ['Cambio Mercato', 'Cambio Offerta', 'Sviluppo portafoglio', 'Voltura'];

export enum ChannelPA {
  STORE_CORNER = 'Store e Corner',
  SPORTELLO = 'Sportello',
  AGENZIA_MID = 'Agenzia MID',
  AGENZIA_D2D = 'Agenzia D2D',
  DOOR_TO_DOOR = 'Door-to-door',
  TELESELLER = 'Teleseller',
  WEB_SALES = 'WebSales',
  TELESELLER_INBOUND = 'Teleseller Inbound',
  TELESELLER_OUTBOUND = 'Teleseller Outbound'
}

export const ChannelKPI: SelectInputType<string>[] = [
  {
    value: 'AGENZIA MID',
    viewValue: 'Agenzia MID'
  },
  {
    value: 'AGENZIA D2D',
    viewValue: 'Door-to-door'
  },
  {
    value: 'SPORTELLO',
    viewValue: 'Sportello'
  },
  {
    value: 'STORE E CORNER',
    viewValue: 'Store e Corner'
  },
  {
    value: 'TELESELLER INBOUND',
    viewValue: 'Teleseller Inbound'
  },
  {
    value: 'TELESELLER OUTBOUND',
    viewValue: 'Teleseller Outbound'
  },
  {
    value: 'WEBSALES',
    viewValue: 'WebSales'
  }
];

export const ChannelReportKPI: SelectInputType<string>[] = [
  {
    value: 'Store e Corner',
    viewValue: 'Store e Corner'
  },
  {
    value: 'SPORTELLO',
    viewValue: 'Sportello'
  },
  {
    value: 'Agenzia MID',
    viewValue: 'Agenzia MID'
  },
  {
    value: 'WebSales',
    viewValue: 'WebSales'
  },
  {
    value: 'Teleseller Inbound',
    viewValue: 'Teleseller Inbound'
  },
  {
    value: 'Teleseller Outbound',
    viewValue: 'Teleseller Outbound'
  }
];

export const CommercialSalesKPI: SelectInputType<string>[] = [
  {
    value: 'ALLACCIO CON ATT',
    viewValue: 'Allaccio con attivazione'
  },
  {
    value: 'ATTIVAZIONE',
    viewValue: 'Attivazione'
  },
  {
    value: 'CAMBIO MERCATO',
    viewValue: 'Cambio Mercato'
  },
  {
    value: 'CAMBIO OFFERTA',
    viewValue: 'Cambio Offerta'
  },
  {
    value: 'PRE-WIN BACK',
    viewValue: 'Pre-Win Back'
  },
  {
    value: 'SUBENTRO',
    viewValue: 'Subentro'
  },
  {
    value: 'SWITCH IN',
    viewValue: 'Switch In'
  },
  {
    value: 'VOLTURA',
    viewValue: 'Voltura'
  },
  {
    value: 'VOLTURA CON SWITCHING ELE',
    viewValue: 'Voltura con Switching Ele'
  }
];

export enum ProfileType {
  EXTERNAL_SALES = 'EXTERNAL_SALES',
  SALES = 'SALES',
  IT_IREN = 'IT_IREN',
  BOE = 'BOE',
  EDITOR = 'EDITOR'
}

export const uploadDocRequired = [
  {
    name: SalesCommercialActionsBE.ATTIVAZIONE,
    identityDoc: true
  },
  {
    name: SalesCommercialActionsBE.SUBENTRO,
    identityDoc: true
  },
  {
    name: SalesCommercialActionsBE.VOLTURA_III,
    identityDoc: true
  },
  {
    name: SalesCommercialActionsBE.VOLTURA_IV,
    identityDoc: true
  },
  {
    name: SalesCommercialActionsBE.ALLACCIO_ATTIVAZIONE,
    identityDoc: true
  },
  {
    name: SalesCommercialActionsBE.SWITCHIN,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.PREWIN_BACK,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.CAMBIO_MERCATO,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.VOLTURA_SWITCH,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.CAMBIO_OFFERTA,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.SWITCHINDOC,
    identityDoc: false
  },
  {
    name: SalesCommercialActionsBE.VOLTURA,
    identityDoc: false
  }
];

export enum CommodityTypesValues {
  ELE = 'ele',
  GAS = 'gas'
}

export enum CommodityTypesViewValues {
  ELE = 'Elettricità',
  GAS = 'Gas'
}

export enum SalesAccountTypes {
  BUSINESS = 'Business',
  RESIDENZIALI = 'Residenziali',
  PERTINENZE = 'Pertinenze',
  CONDOMINI = 'Condomini'
}

export const BusinessSegm = ['pmi', 'soho', 'microbusiness'];

export enum StipulationMethods {
  IREN_DIGITAL_SIGNATURE = 'Firma digitale IREN',
  PRECOMPILED_PLICO = 'Plico Precompilato',
  PREPRINTED_PLICO = 'Plico prestampato',
  EXT_PARTNER_SIGNATURE = 'Firma digitale Partner esterni',
  VOCAL_ORDER = 'Vocal Order'
}

export enum StipulationMethodValue {
  IREN_DIGITAL_SIGNATURE = 'Firma Digitale Iren',
  PRECOMPILED_PLICO = 'Plico Precompilato',
  VOCAL_ORDER = 'Vocal Order',
  PREPRINTED_PLICO = 'Plico Prestampato',
  EXT_PARTNER_SIGNATURE = 'Firma Digitale Partner Esterni'
}

export enum MassiveProcessesValue {
  SWITCH_IN = 'SWITCHIN',
  OFFER_CHANGE = 'CAMBIO-OFFERTA',
  ACTIVATION = 'ATTIVAZIONE-SUBENTRO',
  CONNECTION_WITH_ACTIVATION = 'ATTIVAZIONE-SUBENTRO',
  SWITCHED_ACCOUNT_CHANGE = 'VOLTURA-SWITCHIN',
  TAKE_OVER = 'ATTIVAZIONE-SUBENTRO'
}

export enum MassiveProcessesViewValue {
  SWITCH_IN = 'Switch-In',
  OFFER_CHANGE = 'Cambio Offerta',
  ACTIVATION = 'Attivazione',
  CONNECTION_WITH_ACTIVATION = 'Allaccio con Attivazione',
  SWITCHED_ACCOUNT_CHANGE = 'Voltura con switch',
  TAKE_OVER = 'Subentro'
}

export enum MassiveProcessesBundleValue {
  ACTIVATION_TAKEOVER = 'ATTIVAZIONE-SUBENTRO',
  CONNECTION_WITH_ACTIVATION = 'ATTIVAZIONE-SUBENTRO',
  TAKEOVER = 'ATTIVAZIONE-SUBENTRO',
  SWITCH_IN = 'SWITCHIN',
  SWITCH_IN_NDS = 'SWITCHIN-NDS',
  OFFER_CHANGE = 'CAMBIO-OFFERTA',
  OFFER_CHANGE_NDS = 'CAMBIO-OFFERTA-NDS',
  TRANSFER_SWITCH_IN = 'VOLTURA-SWITCHIN',
  TRANSFER_SWITCH_IN_NDS = 'VOLTURA-SWITCHIN-NDS'
}

export enum MassiveProcessesBundleViewValue {
  ACTIVATION_TAKEOVER = 'Attivazione',
  CONNECTION_WITH_ACTIVATION = 'Allaccio con attivazione',
  TAKEOVER = 'Subentro',
  SWITCH_IN = 'Switch-in',
  SWITCH_IN_NDS = 'Switch-in NDS',
  OFFER_CHANGE = 'Cambio Offerta',
  OFFER_CHANGE_NDS = 'Cambio Offerta NDS',
  TRANSFER_SWITCH_IN = 'Voltura con Switch',
  STAND_ALONE = 'Stand Alone (VAS)'
}

export const profiles = [
  {
    id: 1,
    name: 'Operatore di vendita',
    type: ProfileType.EXTERNAL_SALES
  },
  {
    id: 2,
    name: 'Team Leader',
    type: ProfileType.EXTERNAL_SALES
  },
  {
    id: 3,
    name: 'Supervisor BO di processo',
    type: ProfileType.EXTERNAL_SALES
  },
  {
    id: 4,
    name: 'Channel Account',
    type: ProfileType.SALES
  },
  {
    id: 5,
    name: 'Supervisor di processo IREN',
    type: ProfileType.SALES
  },
  {
    id: 6,
    name: 'IT IREN',
    type: ProfileType.IT_IREN
  },
  {
    id: 7,
    name: 'Supervisor BOE',
    type: ProfileType.BOE
  },
  {
    id: 8,
    name: 'Operatore BOE',
    type: ProfileType.BOE
  },
  {
    id: 9,
    name: 'Editor',
    type: ProfileType.EDITOR
  }
];

export const Manutenzione = 'Manutenzione';
export const ManutenzioneSubType = 'Manutenzione EA';

export const OfferLuminea = [
  {
    value: 67,
    viewValue: 'Luminea'
  }
];
export const contrState = [
  'In Attesa Completamento Luminea RC',
  'In Attesa Completamento Luminea',
  'Attesa assegnazione Luminea',
  'In attesa risoluzione scarto RC Auto',
  'Completamento Modulistica',
  'Attesa assegnazione Luminea RC',
  'DRAFT',
  'Attesa stampa proposta',
  'Attesa conferma plico digitale IREN',
  'In attesa call',
  'In attesa verifica dati',
  'KO firma digitale IREN',
  'Accettato',
  'Annullato'
];

export const CancelPracticeBoeNotesCallKO = ['KO: Ripensamento / annullamento richiesto dal cliente', 'KO: Disconoscimento firma', 'KO: Rifiuta Registrazione', 'KO: Vendita non conforme / non prevista da contratto / canale', 'KO: SMS/MAIL: disconoscimento MAIL o SMS', 'KO: Delega non autorizzata'];
export const CancelPracticeBoeNotesCallNR = ['NR: Numero errato /inesistente', 'NR: Squilla ma mai risposto'];
export const CancelPracticeBoeNotesCheck = ['KO: Dati errati', 'KO: Delega non autorizzata', 'KO: Fuori perimetro', 'KO: Mancanza di documenti necessari per tipologia di acquisizione', 'KO: Plico contrattuale non conforme con i dati inseriti'];

export const CancelPracticeNotes = [
  'KO: Altra prestazione in corso',
  'KO: Disconoscimento/comportamento scorretto agente/agenzia',
  'KO: Credit check ko',
  'KO: Dati errati',
  'KO: Delega non autorizzata',
  'KO: Disconoscimento firma',
  'KO: Flusso TIMG/TIMOE morosità',
  'KO: Fuori perimetro',
  'KO: Punto già attivo con società del gruppo',
  'KO: Punto già presente su SFDC',
  'KO: Ripensamento / annullamento richiesto dal cliente',
  'KO: SMS/MAIL: disconoscimento MAIL o SMS',
  'KO: Vendibilità ko cabina remi non aperta',
  'KO: Numero errato /inesistente',
  'KO: Pending da troppo tempo',
  'KO: Precheck ko - dati incongruenti',
  'KO: Rifiuta registrazione',
  'KO: Salvaguardia / Fui',
  'KO: Turista energetico',
  'KO: Vendita non conforme / non prevista da contratto / canale',
  'KO: Documentazione non completa',
  'KO: Errata offerta / commodity',
  'KO: Errata prestazione',
  'KO: Lavoro non eseguibile'
];

export const CancelPracticeReasons = [
  { viewValue: 'Annullamento manuale', value: ' Annullamento manuale', info: 'Viene inviata la bye bye letter' },
  { viewValue: 'Test tecnico', value: 'Test Tecnico', info: 'Non viene inviata la bye bye letter' }
];

export enum InstallmentsRcAuto {
  EVERY_YEAR_LABEL = 'Annuale',
  EVERY_YEAR_VALUE = 'everyyear',
  MONTHLY_LABEL = 'Mensile',
  MONTHLY_VALUE = 'monthly'
}

export const mapServicesRc: any = {
  'Pagamento Premio': {
    protectionDescription: 'Scegli se frazionare il pagamento in unica soluzione o 12 rate mensili',
    functionDescription: 'Ricordati che il pagamento unica soluzione può essere effettuato con carta di credito o bonifico bancario mentre il pagamento a rate può essere effettuato con carta di credito, bonifico bancario e SDD. La prima rata sarà sempre doppia.'
  },
  'Carrozzerie convenzionate': {
    protectionDescription: 'Scegli se convenzionarti con le nostre carrozzerie',
    functionDescription: 'Se scegli Sì, ricevi uno sconto in polizza e, in caso di sinistro, dovrai far riparare il tuo veicolo in una delle carrozzerie convenzionate Verti.'
  },
  'Tipologia di guida': {
    protectionDescription: 'Scegli la tipologia di guida',
    functionDescription:
      'Guida esperta: sono autorizzati alla guida il contraente e il proprietario, se in possesso di patente, il conducente abituale del veicolo e tutti i patentati con almeno 23 anni di età. Se selezioni, invece, Guida Libera sono autorizzati alla guida tutti i conducenti in possesso di regolare patente.'
  }
};

export const mapCoverageRc: any = {
  'RCA': {
    typecurrency: 'eur',
    protectionDescription: 'RCA',
    functionDescription: 'RCA'
  },
  'ARDVAND': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danneggiamenti volontari al veicolo parcheggiato in strada.',
    functionDescription: 'Copertura per i danni provocati da atti vandalici, tumulti, scioperi, sommosse e altre azioni di danneggiamento volontario.'
  },
  'ARDCRST': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danni a vetri e cristalli del veicolo.',
    functionDescription: 'Copertura dei danni a vetri, lunotti e parabrezza del veicolo.'
  },
  'FUR_INC': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danni al veicolo in caso di incendio, rapine e furti.',
    functionDescription:
      'Copertura in caso di danni materiali e diretti subiti dal veicolo assicurato a seguito di furto (consumato o tentato) e rapina.\n' +
      "La garanzia incendio copre i danni subiti dal veicolo assicurato a causa di incendio non doloso, azione di fulmini, scoppio o esplosione dell'impianto di alimentazione.\n" +
      'Per i veicoli ibridi ed elettrici è coperto anche il furto del cavo di ricarica e lo smaltimento della batteria.'
  },
  'ARDCOLL': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danni al veicolo in caso di incidente con colpa.',
    functionDescription: "Copertura dei danni al veicolo causati dall'urto contro un altro veicolo targato, in sosta o in marcia, in caso di incidente con colpa."
  },
  'INFPD': {
    typecurrency: 'eur',
    protectionDescription: 'Copre gli infortuni subiti da chi è alla guida in caso di incidente anche in caso di torto, negligenza o malore (sia in marcia che ad auto ferma)',
    functionDescription: "Copertura per gli infortuni che determinino la morte o l'invalidità permanente, occorso a chiunque sia alla guida del veicolo assicurato."
  },
  'ARDKASK': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danni che il veicolo potrebbe subire in caso di urti contro ostacoli fissi, collisioni, uscite di strada, ribaltamenti e incidenti con colpa.',
    functionDescription: 'Copertura per i danni subiti dal veicolo in qualsiasi tipologia di sinistro, come urti contro ostacoli fissi, collisioni, uscite di strada e ribaltamenti.'
  },
  'PNCD': {
    typecurrency: 'eur',
    protectionDescription: 'Mantiene invariata la classe di merito interna in caso di incidente con colpa ed evita che la compagnia eserciti la rivalsa in alcuni casi specifici',
    functionDescription:
      'La Classe di merito interna rimane invariata e viene utilizzata per calcolare il preventivo di rinnovo con la stessa compagnia. La garanzia è utilizzabile per un solo sinistro con colpa. Se il conducente è in stato di ebbrezza e provoca un incidente, Verti rinuncia al diritto di rivalsa. Valida solo per il primo sinistro e se il tasso alcolemico rilevato è sotto a 1,5 grammi per litro.'
  },
  'SOS': {
    typecurrency: 'pack',
    protectionDescription: 'Permette di ricevere assistenza per il veicolo in caso di guasto o incidente.',
    functionDescription:
      'Assistenza stradale per il veicolo in caso di guasto, foratura o incidente. La garanzia prevede due offerte:\n' +
      "- Base (disponibile per auto, moto e furgone): offre la riparazione sul posto o il traino del veicolo; il rientro dei passeggeri e l'eventuale recupero del veicolo fuori strada;\n" +
      "- Deluxe (disponibile per auto, moto e furgone) aggiunge l'invio dei pezzi di ricambio all'estero; il rientro sanitario e il veicolo sostitutivo. Per le auto e moto elettriche è previsto anche il trasporto del veicolo fermo alla colonnina di ricarica più vicina o, solo per alcune zone, la ricarica sul posto."
  },
  'TUTG': {
    typecurrency: 'pack',
    protectionDescription: 'Copre le spese legali in caso di coinvolgimenti giudiziari.',
    functionDescription:
      'Copertura delle spese legali per la difesa dei propri interessi, al conducente autorizzato, ai trasportati, al proprietario e al locatario (per i contratti di leasing) con un massimale pari a 7.000 euro. La versione Deluxe (disponibile solo per auto) prevede un massimale pari a 15.000 euro.'
  },
  'ARDATMO': {
    typecurrency: 'eur',
    protectionDescription: 'Copre i danni subiti dal veicolo in caso di eventi atmosferici.',
    functionDescription:
      'Copertura dei danni materiali e diretti causati al veicolo assicurato da: inondazioni, esondazioni, alluvioni, trombe d’aria, uragani, mareggiate, cicloni, tifoni, tempeste, nubifragi, frane, smottamenti e cedimenti del terreno, valanghe, slavine, caduta di neve, ghiaccio e grandine. Sono compresi i danni da allagamenti, da cose trasportate dal vento e da caduta di alberi quando conseguenti agli eventi sopra indicati. Sono compresi i danni agli accessori di serie e anche gli optional, se il loro valore è riportato sulla scheda di polizza oppure fino ad un indennizzo massimo pari a € 1.500,00, a condizione che la loro presenza sia documentabile dalla fattura di acquisto.'
  }
};

//COMMON FUNCTION
export function getPositionByField(propertyName: string, value: any, array: any) {
  for (let i = 0; i < array.length; i++) if (array[i][propertyName] === value) return i;
  return -1;
}

export function clearEmptyKeyFromObject(obj: any) {
  const newObj = { ...obj }; // Crea una copia dell'oggetto
  Object.keys(newObj).forEach(key => {
    if (newObj[key] === '' || newObj[key] === undefined || newObj[key] === null) {
      delete newObj[key];
    }
  });
  return newObj;
}

export const checkAgencyPick = map((el: any) => el.profile?.name === 'IT IREN' || el.profile?.name === 'Channel Account' || el.profile?.name === 'Supervisor di processo IREN');
export const checkPermissionGroup = map((el: any) => el.profile?.name === 'IT IREN');
export const checkEditor = map((el: any) => el.profile?.name !== 'Team Leader' && el.profile?.name !== 'Supervisor BO di processo');
export const checkAgencyPickBOE = map((el: any) => el.profile?.type === 'BOE');
export const checkDownloadMassiveCatalog = map((el: any) => el.profile?.type === ProfileType.EXTERNAL_SALES || el.profile?.type === ProfileType.IT_IREN || el.profile?.name === 'Supervisor di processo IREN');

//REGEX
export const nameSurnameRegex = /^[a-zA-Z][a-zA-Z\s]+$/;
export const emailRegex = /^[A-Za-z0-9][A-Za-z0-9%+_-]*(\.[A-Za-z0-9%+_-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.[A-Za-z]{2,}$/;
export const cfRegex = /^([A-Za-z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1})$/;
export const generalCfRegex = /^[A-Za-z]{6}\d{2}[A-Za-z]\d{2}[A-Za-z]\d{3}[A-Za-z]$|^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$/;
export const pIvaRegex = /^[0-9]{11}$/;
export const onlyNumberRegex = /^[0-9]+$/;
export const regexContractualizedPower = /^(3[1-9]|[4-9]\d|\d{3,})$/;
export const pIvaCndRegex = /^([89])(\d{10})$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/;
export const cfPivaRegex = /^[A-Za-z]{6}\d{2}[A-Za-z]\d{2}[A-Za-z]\d{3}[A-Za-z]$|^(8|9)\d{10}$/i;
export const cfPivaRegexPostSales = /^[A-Za-z]{6}\d{2}[A-Za-z]\d{2}[A-Za-z]\d{3}[A-Za-z]$|^\d{11}$/i;
export const ibanRegex = /^[A-Za-z]{2}[0-9]{2}[A-Za-z]{1}[0-9]{10}[A-Za-z0-9]{12}$/;
export const podRegex = /^IT\d{3}E(?!0{8})[A-Z0-9]{8}$/;
export const pdrRegex = /^\d{14}$/;
export const combinedPodPdrRegex = /^(IT\d{3}E(?!0{8})[A-Z0-9]{8}|\d{14})$/;
export const mobilePhoneRegexPostSales = /^(?:\+39|39)?\d{10}$/;
export const mobilePhoneRegex = /^[1-9](\d{8,10})$/;
export const phoneRegex = /^(0[0-9])(\d{6,13})$/;
export const combinedPhoneRegex = /^(?:[1-9]\d{8,10}|0[0-9]{8}|0[0-9]{9})$/;
export const comuneRegex = /^[A-Z]\d\d\d$/i;
export const onlyNumber = /^[0-9]+$/;
export const codiceMigrazioneRegex = /^[a-zA-Z0-9]{7,19}$/;
export const numerazioneTelefonicaRegex = /^(\d{8,10})(;\d{8,10})*$/;
export const capRegex = /^[0-9]{5}$/;
export const localityRegex = /^[a-zA-Z]{2}$/;
export const buildingNumberRegex = /^[ a-zA-Z0-9,'-_\\']{1,9}$/;
export const SDIRegex = /^[0-9A-Za-z]{7}$/;

//VALIDATORS
export function agentListNoAgencyValidator(control: AbstractControl): { [key: string]: any } | null {
  const agency = control.get('agency')?.value;
  const operator = control.get('operatorId')?.value;
  const codiceFiscale: string = control.get('cf')?.value;
  const agencyT = control.get('agency')?.touched;
  const operatorT = control.get('operatorId')?.touched;
  const codiceFiscaleT = control.get('cf')?.touched;

  if (!agency?.sfid && !operator && !codiceFiscale) {
    control.get('agency')?.setErrors({ oneFieldRequired: true });
    control.get('operatorId')?.setErrors({ oneFieldRequired: true });
    control.get('cf')?.setErrors({ oneFieldRequired: true });
    if (agencyT || operatorT || codiceFiscaleT) {
      control.get('agency')?.markAsTouched();
      control.get('operatorId')?.markAsTouched();
      control.get('cf')?.markAsTouched();
    }
  } else {
    control.get('agency')?.setErrors(null);
    control.get('operatorId')?.setErrors(null);
    control.get('cf')?.setErrors(null);
  }
  return null;
}

export function trackingSalesFiltersValidator(control: AbstractControl): { [key: string]: any } | null {
  const random_code = control.get('random_code')?.value;
  const punto_di_fornitura = control.get('punto_di_fornitura')?.value;
  const codiceFiscale: string = control.get('cf')?.value;
  const random_codeT = control.get('random_code')?.touched;
  const punto_di_fornituraT = control.get('punto_di_fornitura')?.touched;
  const codiceFiscaleT = control.get('cf')?.touched;

  if (!random_code && !punto_di_fornitura && !codiceFiscale) {
    control.get('punto_di_fornitura')?.setErrors({ oneFieldRequired: true });
    control.get('random_code')?.setErrors({ oneFieldRequired: true });
    control.get('cf')?.setErrors({ oneFieldRequired: true });
    if (random_codeT || punto_di_fornituraT || codiceFiscaleT) {
      control.get('punto_di_fornitura')?.markAsTouched();
      control.get('random_code')?.markAsTouched();
      control.get('cf')?.markAsTouched();
    }
  } else {
    control.get('punto_di_fornitura')?.setErrors(null);
    control.get('random_code')?.setErrors(null);
    control.get('cf')?.setErrors(null);
  }
  return null;
}

export const passValidator = function (control: AbstractControl): ValidationErrors | null {
  const hasLowercase = /[a-z]/.test(control.value);
  const hasUppercase = /[A-Z]/.test(control.value);
  const hasNumber = /\d/.test(control.value);
  const hasSpecial = /[!@#$%&*]/.test(control.value);
  const notHasSpecial = /[^!@#$%&*\w\s]/.test(control.value);
  const double = /(.)\1/.test(control.value);
  const isLongEnough = control.value.length >= 14;
  const errors: any = {};
  if (!hasLowercase) {
    errors['lowercase'] = true;
  }
  if (double) {
    errors['double'] = true;
  }
  if (!hasUppercase) {
    errors['uppercase'] = true;
  }
  if (!hasNumber) {
    errors['number'] = true;
  }
  if (!hasSpecial) {
    errors['special'] = true;
  }
  if (!isLongEnough) {
    errors['length'] = true;
  }
  if (notHasSpecial) {
    errors['noSpecial'] = true;
  }
  return Object.keys(errors).length > 0 ? errors : null;
};

export function isOmocode(cf: string): boolean {
  // Regex per verificare se ci sono lettere nelle posizioni numeriche che indicano omocodia
  const omocodiaCheck = /[LMNPQRSTUV]/;

  // Controlla le sezioni numeriche che possono subire omocodia
  const dayPart = cf.slice(9, 11);
  const yearPart = cf.slice(6, 8);
  const cadastralCodePart = cf.slice(12, 15);

  return omocodiaCheck.test(dayPart) || omocodiaCheck.test(yearPart) || omocodiaCheck.test(cadastralCodePart);
}

export function nameSurnameCfValidators(control: AbstractControl): { [key: string]: any } | null {
  const nome = control.get('name')?.value;
  const cognome = control.get('surname')?.value;
  const codiceFiscale: string = control.get('cf')?.value;

  if ((nome && cognome && codiceFiscale) || pIvaRegex.test(codiceFiscale)) {
    const regex = cfRegex;
    if (regex.test(codiceFiscale.toUpperCase())) {
      const expectedCodeName = calcola_codice_nome(nome);
      const expectedCodeSurName = calcola_codice_cognome(cognome);
      const expectedControlCodeCF = calcola_carattere_di_controllo(codiceFiscale.toUpperCase());
      if (codiceFiscale.slice(0, 3).toUpperCase() !== expectedCodeSurName || !codiceFiscale.slice(3, 6).toUpperCase().includes(expectedCodeName) || !codiceFiscale.toUpperCase().includes(expectedCodeSurName) || !codiceFiscale.charAt(15).toUpperCase().includes(expectedControlCodeCF)) {
        control.get('cf')?.setErrors({ nameSurnameCfError: true });
        return null;
      } else if (isOmocode(control.get('cf')?.value) && ((control.get('cfSpecial')?.value != null && !control.get('cfSpecial')?.value) || (control.get('codiceFiscaleStraniero')?.value != null && !control.get('codiceFiscaleStraniero')?.value))) {
        control.get('cf')?.setErrors({ nameSurnameCfOmocodiaError: true });
        return null;
      } else {
        control.get('cf')?.setErrors(null);
        return null;
      }
    } else if (control.get('accountType')?.value === 'Condomini' && pIvaRegex.test(codiceFiscale.toUpperCase())) {
      control.get('cf')?.setErrors(null);
      return null;
    } else {
      control.get('cf')?.setErrors({ required: true });
      control.get('cf')?.setErrors({ nameSurnameCfError: true });
      return null;
    }
  } else {
    control.get('cf')?.setErrors({ required: true });
    control.get('cf')?.setErrors({ nameSurnameCfError: true });
    return null;
  }
}

export function cfOrPartitaIvaValidator(control: AbstractControl): { [key: string]: any } | null {
  const cfBus = control.value.cfBus;

  if (control.get('cfBus')?.hasValidator(Validators.required)) {
    if (!cfBus?.match(generalCfRegex)) {
      control.get('cfBus')?.setErrors({ cfLength: true });
      return null;
    } else if (cfBus.match(pIvaRegex)) {
      for (let i = 0; i < 11; i++) {
        if (isNaN(parseInt(cfBus.charAt(i), 10)) || !cfBus.match(generalCfRegex)) {
          control.get('cfBus')?.setErrors({ partitaIvaNumeric: true });
          return null;
        } else {
          control.get('cfBus')?.setErrors(null);
        }
      }

      let s = 0;
      for (let i = 0; i <= 9; i += 2) {
        s += parseInt(cfBus.charAt(i), 10);
      }

      for (let i = 1; i <= 9; i += 2) {
        let c = 2 * parseInt(cfBus.charAt(i), 10);
        if (c > 9) {
          c = c - 9;
        }
        s += c;
      }

      if ((10 - (s % 10)) % 10 !== parseInt(cfBus.charAt(10), 10) || !cfBus.match(generalCfRegex)) {
        control.get('cfBus')?.setErrors({ partitaIvaInvalid: true });
        return null;
      } else {
        control.get('cfBus')?.setErrors(null);
      }
    } else if (cfBus?.match(generalCfRegex)) {
      control.get('cfBus')?.setErrors(null);
    } else {
      control.get('cfBus')?.setErrors({ partitaIvaInvalid: true });
    }
  }
  return null;
}

export function partitaIvaValidator(control: AbstractControl): { [key: string]: any } | null {
  const partitaIva = control.value.piva;

  if (!(control.get('accountType')?.value === 'Condomini' && control.get('soggetto_non_rilevante_fini_iva')?.value === true)) {
    if (!partitaIva || partitaIva.length !== 11) {
      control.get('piva')?.setErrors({ partitaIvaLength: true });
      return null;
    }

    for (let i = 0; i < 11; i++) {
      if (isNaN(parseInt(partitaIva.charAt(i), 10))) {
        control.get('piva')?.setErrors({ partitaIvaNumeric: true });
        return null;
      }
    }

    let s = 0;
    for (let i = 0; i <= 9; i += 2) {
      s += parseInt(partitaIva.charAt(i), 10);
    }

    for (let i = 1; i <= 9; i += 2) {
      let c = 2 * parseInt(partitaIva.charAt(i), 10);
      if (c > 9) {
        c = c - 9;
      }
      s += c;
    }

    if ((10 - (s % 10)) % 10 !== parseInt(partitaIva.charAt(10), 10)) {
      control.get('piva')?.setErrors({ partitaIvaInvalid: true });
      return null;
    }
  } else {
    control.get('piva')?.setErrors(null);
  }
  return null;
}

export function checkPercetage(control: AbstractControl): { [key: string]: any } | null {
  const percentages = [...control.value.map((el: any) => el.percentage)];
  if (percentages) {
    let total: number = 0;
    percentages.forEach((el: number) => {
      total += el;
    });
    if (total !== 100) {
      return { percentageMax: true };
    }
  }
  return null;
}

export function calcola_carattere_di_controllo(codice_fiscale: string) {
  let val: number = 0;
  for (let i = 0; i < 15; i++) {
    let c = codice_fiscale[i];
    if (i % 2) val += tavola_carattere_di_controllo_valore_caratteri_pari[c as keyof typeof tavola_carattere_di_controllo_valore_caratteri_pari];
    else val += tavola_carattere_di_controllo_valore_caratteri_dispari[c as keyof typeof tavola_carattere_di_controllo_valore_caratteri_dispari];
  }
  val = val % 26;
  return tavola_carattere_di_controllo.charAt(val);
}

export function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl?.split(','),
    mime = arr[0]?.match(/:(.*?);/)?.[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function calcola_codice_cognome(cognome: string) {
  let codice_cognome = ottieni_consonanti(cognome);
  codice_cognome += ottieni_vocali(cognome);
  codice_cognome += 'XXX';
  codice_cognome = codice_cognome.substring(0, 3);
  return codice_cognome.toUpperCase();
}

export function calcola_codice_nome(nome: string) {
  let codice_nome = ottieni_consonanti(nome);
  if (codice_nome.length >= 4) {
    codice_nome = codice_nome.charAt(0) + codice_nome.charAt(2) + codice_nome.charAt(3);
  } else {
    codice_nome += ottieni_vocali(nome);
    codice_nome += 'XXX';
    codice_nome = codice_nome.substr(0, 3);
  }
  return codice_nome.toUpperCase();
}

export function ottieni_consonanti(str: string) {
  return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, '');
}

export function ottieni_vocali(str: string) {
  return str.replace(/[^AEIOU]/gi, '');
}

//MODAL CONFIG
export function openDialog(modal: Partial<Modal>) {
  const dialogRef = modal.dialog!.open(ModalComponent, {
    panelClass: 'custom-dialog',
    disableClose: true,
    width: modal.width,
    height: modal.height,
    data: {
      errorCode: modal.errorCode,
      title: modal.title,
      message: modal.message,
      status: modal.status,
      ctaType: modal.ctaType,
      noCopy: modal.noCopy,
      optionalMessage: modal.optionalMessage,
      copyOptionalMessage: modal.copyOptionalMessage,
      typeOptionalMessage: modal.typeOptionalMessage,
      funnelCommodity: modal.funnelCommodity,
      titleStyle: modal.titleStyle,
      messageStyle: modal.messageStyle,
      pointBreak: modal.pointBreak,
      proceedLabel: modal.proceedLabel,
      cancelLabel: modal.cancelLabel
    }
  });
  return dialogRef;
}

export enum ModalTitles {
  HOMEPAGE = 'Homepage',
  CATALOG = 'Catalogo',
  SCRIPT = 'Script',
  SALES = 'Pre-Check e Vendita',
  INVIO_PREVENTIVO = 'Invio Preventivo',
  IBAN_CHECK = 'Iban check',
  MASSIVE_PRACTICES = 'Gestione Pratiche Massive', //
  PREWIN_BACK_CONF = 'Configuratore prewinback',
  FIRMA_DIGITALE = 'Firma Digitale',
  TRACKING_SALES = 'Tracking Pratiche - Sales',
  TRACKING_LUMINEA = 'Tracking Pratiche - Luminea',
  TRACKING_POSTSALES = 'Tracking Pratiche - PostSales',
  TRACKING_BOE = 'Tracking Boe',
  CRUSCOTTO_PRIORITA = 'Cruscotto Priorità',
  POST_SALES = 'Post-Sales',
  KPI_MONITORING = 'Kpi Monitoring',
  TRUSTPILOT_KPI_MONITORING = 'Trustpilot Kpi Monitoring',
  AGENT_PROFILE = 'Anagrafica Agenti',
  TRAINING = 'Formazione',
  LEAD_MANAGEMENT = 'Gestione Lead',
  CUSTOMER_MANAGEMENT = 'Gestione Clienti',
  LEAD_ASSIGN = 'Assegnazione Lead',
  ASSIGNED_CAMPAIGN = 'Campagne Assegnate',
  INSTANT_CALL = 'Instant Call',
  LOGIN = 'Autenticazione',
  BOE = 'Boe',
  TENDER = 'Gare',
  AGENCY_MANAGER = 'Configurazione agenzie',
  EDITOR = 'Editor',
  AVAILABILITY = 'Indicazione Disponibilità',
  RPO = 'Esiti RPO',
  FLAT_OFFER = 'Preventivatore FLAT',
  VECCHIA_BOLLETTA = 'Scarica vecchia bolletta',
  DOWNLOAD_LEAD_RC = 'Download lead targhe',
  ARRICCHIMENTI_LEAD_RC = 'Arricchimenti lead rc',
  REDISPATCH_LEAD = 'Ridispacciamento lead',
  TRUSTPILOT = 'Trustpilot',
  OVER85 = 'Whitelist over 85',
  ADD_EXCEPTION_DISPATCHING = 'Eccezione dispatching aggiunta',
  NOT_ADD_EXCEPTION_DISPATCHING = 'Eccezione non aggiunta'
}

export const ALLACCIO_CODE = '00000000000000';

//GET ERROR MESSAGE
export function getErrorMessage(errorID: string): string {
  switch (errorID) {
    case '400':
      return 'Bad Request';
    case '403':
      return 'Forbidden';
    case '404':
      return 'Page Not Found';
    case '500':
      return 'Internal Server Error';
    case '503':
      return 'Service Unavailable';
    case '999':
      return 'Errore Generico';
    default:
      return 'Error';
  }
}

export enum rcaError {
  FAST_QUOTE_ERROR = '000',
  PLATE_ERROR = '001',
  BIRTHDAY_ERROR = '002',
  AFFINITY_ERROR = '003',
  AFFINITY_EMPTY_ERROR = '004',
  DATA_NON_VALID = '005',
  GENERIC_ERROR = '999'
}

export const consumoAnnuoEleMapping = [
  { potenza: '0.5', domestico: '335', altriUsi: '400' },
  { potenza: '1', domestico: '670', altriUsi: '800' },
  { potenza: '1.5', domestico: '1005', altriUsi: '1200' },
  { potenza: '2', domestico: '1340', altriUsi: '1600' },
  { potenza: '2.5', domestico: '1675', altriUsi: '2000' },
  { potenza: '3', domestico: '2010', altriUsi: '2400' },
  { potenza: '3.5', domestico: '2345', altriUsi: '2800' },
  { potenza: '4', domestico: '2680', altriUsi: '3200' },
  { potenza: '4.5', domestico: '3015', altriUsi: '3600' },
  { potenza: '5', domestico: '3350', altriUsi: '4000' },
  { potenza: '5.5', domestico: '3685', altriUsi: '4400' },
  { potenza: '6', domestico: '4020', altriUsi: '4800' },
  { potenza: '7', domestico: '4690', altriUsi: '5600' },
  { potenza: '8', domestico: '5360', altriUsi: '6400' },
  { potenza: '9', domestico: '6030', altriUsi: '7200' },
  { potenza: '10', domestico: '6700', altriUsi: '8000' },
  { potenza: '15', domestico: '10050', altriUsi: '12000' },
  { potenza: '20', domestico: '13400', altriUsi: '16000' },
  { potenza: '25', domestico: '16750', altriUsi: '20000' },
  { potenza: '30', domestico: '20100', altriUsi: '24000' },
  { potenza: '> 30', domestico: '670', altriUsi: '800' }
];

export const consumoAnnuoGasMapping = [
  { profilo: 'C1 - riscaldamento', consumo: '5001', consumoMax: '' },
  { profilo: 'C2 - cottura cibi e/o produzione acqua calda sanitaria', consumo: '500', consumoMax: '' },
  { profilo: 'C3 - riscaldamento + cottura cibi e/o produzione acqua calda sanitaria', consumo: '1550', consumoMax: '' },
  { profilo: 'C4 - condizionamento', consumo: '1550', consumoMax: '' },
  { profilo: 'C5 - condizionamento + riscaldamento', consumo: '2500', consumoMax: '' },
  { profilo: 'T1 - tecnologico (artigianale - industriale)', consumo: '5000', consumoMax: '' },
  { profilo: 'T2 - tecnologico + riscaldamento', consumo: '5000', consumoMax: '' }
];

export const REFERENT_QUALIFICATION = ['Amministratore Pro Tempore', 'Titolare del contratto', 'Legale Rappresentante Società'];

export const MAGGIOR_TUTELA = 'maggior tutela';

export const TUTELA_GRADUALE = 'tutela graduale';
export const LIBERO = 'libero';

export const TUTELA_GRADUALE_MICROIMPRESE = 'Tutela Graduale Microimprese';

export const ATECO_CODES = [
  '01.11 - Coltivazione di cereali (escluso il riso), legumi da granella e semi oleosi',
  '01.12 - Coltivazione di riso',
  '01.13 - Coltivazione di ortaggi e meloni, radici e tuberi',
  '01.14 - Coltivazione di canna da zucchero',
  '01.15 - Coltivazione di tabacco',
  '01.16 - Coltivazione di piante tessili',
  '01.19 - Floricoltura e coltivazione di altre colture non permanenti',
  '01.21 - Coltivazione di uva',
  '01.22 - Coltivazione di frutta di origine tropicale e subtropicale',
  '01.23 - Coltivazione di agrumi',
  '01.24 - Coltivazione di pomacee e frutta a nocciolo',
  '01.25 - Coltivazione di altri alberi da frutta, frutti di bosco e in guscio',
  '01.26 - Coltivazione di frutti oleosi',
  '01.27 - Coltivazione di piante per la produzione di bevande',
  '01.28 - Coltivazione di spezie, piante aromatiche e farmaceutiche',
  '01.29 - Coltivazione di altre colture permanenti (inclusi alberi di Natale)',
  '01.30 - Riproduzione delle piante',
  '01.41 - Allevamento di bovini da latte',
  '01.42 - Allevamento di altri bovini e di bufalini',
  '01.43 - Allevamento di cavalli e altri equini',
  '01.44 - Allevamento di cammelli e camelidi',
  '01.45 - Allevamento di ovini e caprini',
  '01.46 - Allevamento di suini',
  '01.47 - Allevamento di pollame',
  '01.49 - Allevamento di altri animali',
  "01.50 - Coltivazioni agricole associate all'allevamento di animali: attività mista",
  '01.61 - Attività di supporto alla produzione vegetale',
  '01.62 - Attività di supporto alla produzione animale',
  '01.63 - Attività successive alla raccolta',
  '01.64 - Lavorazione delle sementi per la semina',
  '01.70 - Caccia, cattura di animali e servizi connessi',
  '02.10 - Silvicoltura ed altre attività forestali',
  '02.20 - Utilizzo di aree forestali',
  '02.30 - Raccolta di prodotti selvatici non legnosi',
  '02.40 - Servizi di supporto per la silvicoltura',
  '03.11 - Pesca marina',
  '03.12 - Pesca in acque dolci',
  '03.21 - Acquacoltura marina',
  '03.22 - Acquacoltura in acque dolci',
  '05.10 - Estrazione di antracite',
  '05.20 - Estrazione di lignite',
  '06.10 - Estrazione di petrolio greggio',
  '06.20 - Estrazione di gas naturale',
  '07.10 - Estrazione di minerali metalliferi ferrosi',
  '07.21 - Estrazione di minerali di uranio e di torio',
  '07.29 - Estrazione di altri minerali metalliferi non ferrosi',
  '08.11 - Estrazione di pietre ornamentali e da costruzione, calcare, pietra da gesso, creta e ardesia',
  '08.12 - Estrazione di ghiaia e sabbia; estrazione di argille e caolino',
  "08.91 - Estrazione di minerali per l'industria chimica e per la produzione di fertilizzanti",
  '08.92 - Estrazione di torba',
  '08.93 - Estrazione di sale',
  '08.99 - Estrazione di altri minerali da cave e miniere nca',
  "09.10 - Attività di supporto all'estrazione di petrolio e di gas naturale",
  "09.90 - Attività di supporto per l'estrazione da cave e miniere di altri minerali",
  '10.11 - Lavorazione e conservazione di carne (escluso volatili)',
  '10.12 - Lavorazione e conservazione di carne di volatili',
  '10.13 - Produzione di prodotti a base di carne (inclusa la carne di volatili)',
  '10.20 - Lavorazione e conservazione di pesce, crostacei e molluschi',
  '10.31 - Lavorazione e conservazione delle patate',
  '10.32 - Produzione di succhi di frutta e di ortaggi',
  '10.39 - Altra Lavorazione e conservazione di frutta e di ortaggi',
  '10.41 - Produzione di oli e grassi',
  '10.42 - Produzione di margarina e di grassi commestibili simili',
  '10.51 - Industria lattiero-casearia, trattamento igienico, conservazione del latte',
  '10.52 - Produzione di gelati',
  '10.61 - Lavorazione delle granaglie',
  '10.62 - Produzione di amidi e di prodotti amidacei',
  '10.71 - Produzione di pane; prodotti di pasticceria freschi',
  '10.72 - Produzione di fette biscottate e di biscotti; produzione di prodotti di pasticceria conservati',
  '10.73 - Produzione di paste alimentari, di cuscus e di prodotti farinacei simili',
  '10.81 - Produzione di zucchero',
  '10.82 - Produzione di cacao, cioccolato, caramelle e confetterie',
  '10.83 - Lavorazione del tè e del caffè',
  '10.84 - Produzione di condimenti e spezie',
  '10.85 - Produzione di pasti e piatti preparati',
  '10.86 - Produzione di preparati omogeneizzati e di alimenti dietetici',
  '10.89 - Produzione di prodotti alimentari nca',
  "10.91 - Produzione di mangimi per l'alimentazione degli animali da allevamento",
  "10.92 - Produzione di prodotti per l'alimentazione degli animali da compagnia",
  '11.01 - Distillazione, rettifica e miscelatura degli alcolici',
  '11.02 - Produzione di vini da uve',
  '11.03 - Produzione di sidro e di altri vini a base di frutta',
  '11.04 - Produzione di altre bevande fermentate non distillate',
  '11.05 - Produzione di birra',
  '11.06 - Produzione di malto',
  '11.07 - Industria delle bibite analcoliche, delle acque minerali e di altre acque in bottiglia',
  '12.00 - Industria del tabacco',
  '13.10 - Preparazione e filatura di fibre tessili',
  '13.20 - Tessitura',
  '13.30 - Finissaggio dei tessili',
  '13.91 - Fabbricazione di tessuti a maglia',
  '13.92 - Confezionamento di articoli tessili (esclusi gli articoli di abbigliamento)',
  '13.93 - Fabbricazione di tappeti e moquette',
  '13.94 - Fabbricazione di spago, corde, funi e reti',
  '13.95 - Fabbricazione di tessuti non tessuti e di articoli in tali materie (esclusi gli articoli di abbigliamento)',
  '13.96 - Fabbricazione di articoli tessili tecnici ed industriali',
  '13.99 - Fabbricazione di altri prodotti tessili nca',
  '14.11 - Confezione di abbigliamento in pelle',
  '14.12 - Confezione di indumenti da lavoro',
  '14.13 - Confezione di altro abbigliamento esterno',
  '14.14 - Confezione di biancheria intima',
  '14.19 - Confezione di altri articoli di abbigliamento ed accessori',
  '14.20 - Confezione di articoli in pelliccia',
  '14.31 - Fabbricazione di articoli di calzetteria in maglia',
  '14.39 - Fabbricazione di altri articoli di maglieria',
  '15.11 - Preparazione e concia del cuoio; preparazione e tintura di pellicce',
  '15.12 - Fabbricazione di articoli da viaggio, borse e simili, pelletteria e selleria',
  '15.20 - Fabbricazione di calzature',
  '16.10 - Taglio e piallatura del legno',
  '16.21 - Fabbricazione di fogli da impiallacciatura e di pannelli a base di legno',
  '16.22 - Fabbricazione di pavimenti in parquet assemblato',
  "16.23 - Fabbricazione di altri prodotti di carpenteria in legno e falegnameria per l'edilizia",
  '16.24 - Fabbricazione di imballaggi in legno',
  '16.29 - Fabbricazione di altri prodotti in legno, sughero, paglia e materiali da intreccio',
  '17.11 - Fabbricazione di pasta-carta',
  '17.12 - Fabbricazione di carta e cartone',
  '17.21 - Fabbricazione di carta e cartone ondulato e di imballaggi di carta e cartone',
  '17.22 - Fabbricazione di prodotti igienico-sanitari e per uso domestico in carta e ovatta di cellulosa',
  '17.23 - Fabbricazione di prodotti cartotecnici',
  '17.24 - Fabbricazione di carta da parati',
  '17.29 - Fabbricazione di altri articoli di carta e cartone',
  '18.11 - Stampa di giornali',
  '18.12 - Altra stampa',
  '18.13 - Lavorazioni preliminari alla stampa e ai media',
  '18.14 - Legatoria e servizi connessi',
  '18.20 - Riproduzione di supporti registrati',
  '19.10 - Fabbricazione di prodotti di cokeria',
  '19.20 - Fabbricazione di prodotti derivanti dalla raffinazione del petrolio',
  '20.11 - Fabbricazione di gas industriali',
  '20.12 - Fabbricazione di coloranti e pigmenti',
  '20.13 - Fabbricazione di altri prodotti chimici di base inorganici',
  '20.14 - Fabbricazione di altri prodotti chimici di base organici',
  '20.15 - Fabbricazione di fertilizzanti e composti azotati',
  '20.16 - Fabbricazione di materie plastiche in forme primarie',
  '20.17 - Fabbricazione di gomma sintetica in forme primarie',
  "20.20 - Fabbricazione di agrofarmaci e di altri prodotti chimici per l'agricoltura",
  '20.30 - Fabbricazione di pitture, vernici e smalti, inchiostri da stampa e adesivi sintetici (mastici)',
  '20.41 - Fabbricazione di saponi e detergenti, di prodotti per la pulizia e la lucidatura',
  '20.42 - Fabbricazione di profumi e cosmetici',
  '20.51 - Fabbricazione di esplosivi',
  '20.52 - Fabbricazione di colle',
  '20.53 - Fabbricazione di oli essenziali',
  '20.59 - Fabbricazione di prodotti chimici nca',
  '20.60 - Fabbricazione di fibre sintetiche e artificiali',
  '21.10 - Fabbricazione di prodotti farmaceutici di base',
  '21.20 - Fabbricazione di medicinali e preparati farmaceutici',
  "22.11 - Fabbricazione di pneumatici e camere d'aria; rigenerazione e ricostruzione di pneumatici",
  '22.19 - Fabbricazione di altri prodotti in gomma',
  '22.21 - Fabbricazione di lastre, fogli, tubi e profilati in materie plastiche',
  '22.22 - Fabbricazione di imballaggi in materie plastiche',
  "22.23 - Fabbricazione di articoli in plastica per l'edilizia",
  '22.29 - Fabbricazione di altri articoli in materie plastiche',
  '23.11 - Fabbricazione di vetro piano',
  '23.12 - Lavorazione e trasformazione del vetro piano',
  '23.13 - Fabbricazione di vetro cavo',
  '23.14 - Fabbricazione di fibre di vetro',
  '23.19 - Fabbricazione e lavorazione di altro vetro (incluso vetro per usi tecnici), lavorazione di vetro cavo',
  '23.20 - Fabbricazione di prodotti refrattari',
  '23.31 - Fabbricazione di piastrelle in ceramica per pavimenti e rivestimenti',
  "23.32 - Fabbricazione di mattoni, tegole ed altri prodotti per l'edilizia in terracotta",
  '23.41 - Fabbricazione di prodotti in ceramica per usi domestici e ornamentali',
  '23.42 - Fabbricazione di articoli sanitari in ceramica',
  '23.43 - Fabbricazione di isolatori e di pezzi isolanti in ceramica',
  '23.44 - Fabbricazione di altri prodotti in ceramica per uso tecnico e industriale',
  '23.49 - Fabbricazione di altri prodotti in ceramica',
  '23.51 - Produzione di cemento',
  '23.52 - Produzione di calce e gesso',
  "23.61 - Fabbricazione di prodotti in calcestruzzo per l'edilizia",
  "23.62 - Fabbricazione di prodotti in gesso per l'edilizia",
  "23.63 - Produzione di calcestruzzo pronto per l'uso",
  '23.64 - Produzione di malta',
  '23.65 - Fabbricazione di prodotti in fibrocemento',
  '23.69 - Fabbricazione di altri prodotti in calcestruzzo, gesso e cemento',
  '23.70 - Taglio, modellatura e finitura di pietre',
  '23.91 - Produzione di prodotti abrasivi',
  '23.99 - Fabbricazione di altri prodotti in minerali non metalliferi nca',
  '24.10 - Siderurgia',
  '24.20 - Fabbricazione di tubi, condotti, profilati cavi e relativi accessori in acciaio (esclusi quelli in acciaio colato)',
  '24.31 - Stiratura a freddo di barre',
  '24.32 - Laminazione a freddo di nastri',
  '24.33 - Profilatura mediante formatura o piegatura a freddo; fabbricazione di pannelli stratificati in acciaio',
  '24.34 - Trafilatura a freddo',
  '24.41 - Produzione di metalli preziosi',
  '24.42 - Produzione di alluminio',
  '24.43 - Produzione di piombo, zinco e stagno e semilavorati',
  '24.44 - Produzione di rame',
  '24.45 - Produzione di altri metalli non ferrosi',
  '24.46 - Trattamento dei combustibili nucleari',
  '24.51 - Fusione di ghisa',
  '24.52 - Fusione di acciaio',
  '24.53 - Fusione di metalli leggeri',
  '24.54 - Fusione di altri metalli non ferrosi',
  '25.11 - Fabbricazione di strutture metalliche e di parti di strutture',
  '25.12 - Fabbricazione di porte e finestre in metallo',
  '25.21 - Fabbricazione di radiatori e contenitori in metallo per caldaie per il riscaldamento centrale',
  '25.29 - Fabbricazione di altre cisterne, serbatoi e contenitori in metallo',
  '25.30 - Fabbricazione di generatori di vapore (esclusi i contenitori in metallo per caldaie per il riscaldamento centrale ad acqua calda)',
  '25.40 - Fabbricazione di armi e munizioni',
  '25.50 - Fucinatura, imbutitura, stampaggio e profilatura dei metalli; metallurgia delle polveri',
  '25.61 - Trattamento e rivestimento dei metalli',
  '25.62 - Lavori di meccanica generale',
  '25.71 - Fabbricazione di articoli di coltelleria e posateria',
  '25.72 - Fabbricazione di serrature e cerniere',
  '25.73 - Fabbricazione di utensileria',
  '25.91 - Fabbricazione di bidoni in acciaio e di contenitori analoghi',
  '25.92 - Fabbricazione di imballaggi leggeri in metallo',
  '25.93 - Fabbricazione di prodotti fabbricati con fili metallici, catene e molle',
  '25.94 - Fabbricazione di articoli di bulloneria',
  '25.99 - Fabbricazione di altri prodotti in metallo nca',
  '26.11 - Fabbricazione di componenti elettronici',
  '26.12 - Fabbricazione di schede elettroniche assemblate',
  '26.20 - Fabbricazione di computer e unità periferiche',
  '26.30 - Fabbricazione di apparecchiature per le telecomunicazioni',
  '26.40 - Fabbricazione di prodotti di elettronica di consumo audio e video',
  '26.51 - Fabbricazione di strumenti e apparecchi di misurazione, prova e navigazione (esclusi quelli ottici)',
  '26.52 - Fabbricazione di orologi',
  '26.60 - Fabbricazione di strumenti per irradiazione, apparecchiature elettromedicali ed elettroterapeutiche',
  '26.70 - Fabbricazione di strumenti ottici e attrezzature fotografiche',
  '26.80 - Fabbricazione di supporti magnetici ed ottici',
  '27.11 - Fabbricazione di motori, generatori e trasformatori elettrici',
  "27.12 - Fabbricazione di apparecchiature per le reti di distribuzione e il controllo dell'elettricità",
  '27.20 - Fabbricazione di batterie di pile ed accumulatori elettrici',
  '27.31 - Fabbricazione di cavi a fibra ottica',
  '27.32 - Fabbricazione di altri fili e cavi elettrici ed elettronici',
  '27.33 - Fabbricazione di attrezzature per cablaggio',
  '27.40 - Fabbricazione di apparecchiature per illuminazione',
  '27.51 - Fabbricazione di elettrodomestici',
  '27.52 - Fabbricazione di apparecchi per uso domestico non elettrici',
  '27.90 - Fabbricazione di altre apparecchiature elettriche',
  '28.11 - Fabbricazione di motori e turbine (esclusi i motori per aeromobili, veicoli e motocicli)',
  '28.12 - Fabbricazione di apparecchiature fluidodinamiche',
  '28.13 - Fabbricazione di altre pompe e compressori',
  '28.14 - Fabbricazione di altri rubinetti e valvole',
  '28.15 - Fabbricazione di cuscinetti, ingranaggi e organi di trasmissione (esclusi quelli idraulici)',
  '28.21 - Fabbricazione di forni, bruciatori e sistemi di riscaldamento',
  '28.22 - Fabbricazione di macchine e apparecchi di sollevamento e movimentazione',
  '28.23 - Fabbricazione di macchine ed attrezzature per ufficio (esclusi computer e unità periferiche)',
  '28.24 - Fabbricazione di utensili portatili a motore',
  '28.25 - Fabbricazione di attrezzature di uso non domestico per la refrigerazione e la ventilazione',
  '28.29 - Fabbricazione di altre macchine di impiego generale nca',
  "28.30 - Fabbricazione di macchine per l'agricoltura e la silvicoltura",
  '28.41 - Fabbricazione di macchine utensili per la formatura dei metalli',
  '28.49 - Fabbricazione di altre macchine utensili',
  '28.91 - Fabbricazione di macchine per la metallurgia',
  '28.92 - Fabbricazione di macchine da miniera, cava e cantiere',
  "28.93 - Fabbricazione di macchine per l'industria alimentare, delle bevande e del tabacco",
  "28.94 - Fabbricazione di macchine per le industrie tessili, dell'abbigliamento e del cuoio(incluse parti e accessori)",
  "28.95 - Fabbricazione di macchine per l'industria della carta e del cartone(incluse parti e accessori)",
  "28.96 - Fabbricazione di macchine per l'industria delle materie plastiche e della gomma(incluse parti e accessori)",
  '28.99 - Fabbricazione di macchine per impieghi speciali nca (incluse parti e accessori)',
  '29.10 - Fabbricazione di autoveicoli',
  '29.20 - Fabbricazione di carrozzerie per autoveicoli, rimorchi e semirimorchi',
  '29.31 - Fabbricazione di apparecchiature elettriche ed elettroniche per autoveicoli e loro motori',
  '29.32 - Fabbricazione di altre parti ed accessori per autoveicoli',
  '30.11 - Costruzione di navi e di strutture galleggianti',
  '30.12 - Costruzione di imbarcazioni da diporto e sportive',
  '30.20 - Costruzione di locomotive e di materiale rotabile ferro-tranviario',
  '30.30 - Fabbricazione di aeromobili, di veicoli spaziali e dei relativi dispositivi',
  '30.40 - Fabbricazione di veicoli militari da combattimento',
  '30.91 - Fabbricazione di motocicli (inclusi i motori)',
  '30.92 - Fabbricazione di biciclette e veicoli per invalidi',
  '30.99 - Fabbricazione di altri mezzi di trasporto nca',
  '31.01 - Fabbricazione di mobili per ufficio e negozi',
  '31.02 - Fabbricazione di mobili per cucina',
  '31.03 - Fabbricazione di materassi',
  '31.09 - Fabbricazione di altri mobili',
  '32.11 - Coniazione di monete',
  '32.12 - Fabbricazione di oggetti di gioielleria e oreficeria e articoli connessi',
  '32.13 - Fabbricazione di bigiotteria e articoli simili',
  '32.20 - Fabbricazione di strumenti musicali',
  '32.30 - Fabbricazione di articoli sportivi',
  '32.40 - Fabbricazione di giochi e giocattoli',
  '32.50 - Fabbricazione di strumenti e forniture mediche e dentistiche',
  '32.91 - Fabbricazione di scope e spazzole',
  '32.99 - Altre industrie manifatturiere nca',
  '33.11 - Riparazione e manutenzione di prodotti in metallo',
  '33.12 - Riparazione e manutenzione di macchinari',
  '33.13 - Riparazione e manutenzione di apparecchiature elettroniche ed ottiche',
  '33.14 - Riparazione e manutenzione di apparecchiature elettriche',
  '33.15 - Riparazione e manutenzione di navi e imbarcazioni (esclusi i loro motori)',
  '33.16 - Riparazione e manutenzione di aeromobili e di veicoli spaziali',
  '33.17 - Riparazione e manutenzione di locomotive e di materiale rotabile ferro-tranviario (esclusi i loro motori)',
  '33.19 - Riparazione di altre apparecchiature',
  '33.20 - Installazione di macchine ed apparecchiature industriali',
  '35.11 - Produzione di energia elettrica',
  '35.12 - Trasmissione di energia elettrica',
  '35.13 - Distribuzione di energia elettrica',
  '35.14 - Commercio di energia elettrica',
  '35.21 - Produzione di gas',
  '35.22 - Distribuzione di combustibili gassosi mediante condotte',
  '35.23 - Commercio di gas distribuito mediante condotte',
  '35.30 - Fornitura di vapore e aria condizionata',
  '36.00 - Raccolta, trattamento e fornitura di acqua',
  '37.00 - Gestione delle reti fognarie',
  '38.11 - Raccolta di rifiuti non pericolosi',
  '38.12 - Raccolta di rifiuti pericolosi',
  '38.21 - Trattamento e smaltimento di rifiuti non pericolosi',
  '38.22 - Trattamento e smaltimento di rifiuti pericolosi',
  '38.31 - Demolizione di carcasse e cantieri di demolizione navali',
  '38.32 - Recupero e cernita di materiali',
  '39.00 - Attività di risanamento e altri servizi di gestione dei rifiuti',
  '41.10 - Sviluppo di progetti immobiliari',
  '41.20 - Costruzione di edifici residenziali e non residenziali',
  '42.11 - Costruzione di strade e autostrade',
  '42.12 - Costruzione di linee ferroviarie e metropolitane',
  '42.13 - Costruzione di ponti e gallerie',
  '42.21 - Costruzione di opere di pubblica utilità per il trasporto di fluidi',
  "42.22 - Costruzione di opere di pubblica utilità per l'energia elettrica e le telecomunicazioni",
  '42.91 - Costruzione di opere idrauliche',
  '42.99 - Costruzione di altre opere di ingegneria civile nca',
  '43.11 - Demolizione',
  '43.12 - Preparazione del cantiere edile',
  '43.13 - Trivellazioni e perforazioni',
  '43.21 - Installazione di impianti elettrici',
  "43.22 - Installazione di impianti idraulici, di riscaldamento e di condizionamento dell'aria",
  '43.29 - Altri lavori di costruzione e installazione',
  '43.31 - Intonacatura',
  '43.32 - Posa in opera di infissi',
  '43.33 - Rivestimento di pavimenti e di muri',
  '43.34 - Tinteggiatura e posa in opera di vetri',
  '43.39 - Altri lavori di completamento e di finitura degli edifici',
  '43.91 - Realizzazione di coperture',
  '43.99 - Altri lavori specializzati di costruzione nca',
  '45.11 - Commercio di autovetture e di autoveicoli leggeri',
  '45.19 - Commercio di altri autoveicoli',
  '45.20 - Manutenzione e riparazione di autoveicoli',
  "45.31 - Commercio all'ingrosso ed intermediazione di parti e accessori di autoveicoli",
  '45.32 - Commercio al dettaglio di parti e accessori di autoveicoli',
  '45.40 - Commercio, manutenzione e riparazione di motocicli e relative parti ed accessori',
  '46.11 - Intermediari del commercio di materie prime agricole, di animali vivi, di materie prime tessili e di semilavorati',
  '46.12 - Intermediari del commercio di combustibili, minerali, metalli e prodotti chimici',
  '46.13 - Intermediari del commercio di legname e materiali da costruzione',
  '46.14 - Intermediari del commercio di macchinari, impianti industriali, navi e aeromobili',
  '46.15 - Intermediari del commercio di mobili, articoli per la casa e ferramenta',
  '46.16 - Intermediari del commercio di prodotti tessili, abbigliamento, pellicce, calzature e articoli in pelle',
  '46.17 - Intermediari del commercio di prodotti alimentari, bevande e tabacco',
  '46.18 - Intermediari del commercio specializzato in altri prodotti',
  '46.19 - Intermediari del commercio di vari prodotti senza prevalenza di alcuno',
  "46.21 - Commercio all'ingrosso di cereali, tabacco grezzo, sementi e alimenti per il bestiame(mangimi)",
  "46.22 - Commercio all'ingrosso di fiori e piante",
  "46.23 - Commercio all'ingrosso di animali vivi",
  "46.24 - Commercio all'ingrosso di pelli e cuoio",
  "46.31 - Commercio all'ingrosso di frutta e ortaggi freschi o conservati",
  "46.32 - Commercio all'ingrosso di carne e di prodotti a base di carne",
  "46.33 - Commercio all'ingrosso di prodotti lattiero-caseari, uova, oli e grassi commestibili",
  "46.34 - Commercio all'ingrosso di bevande",
  "46.35 - Commercio all'ingrosso di prodotti del tabacco",
  "46.36 - Commercio all'ingrosso di zucchero, cioccolato, dolciumi e prodotti da forno",
  "46.37 - Commercio all'ingrosso di caffè, tè, cacao e spezie",
  "46.38 - Commercio all'ingrosso di altri prodotti alimentari, inclusi pesci, crostacei e molluschi",
  "46.39 - Commercio all'ingrosso non specializzato di prodotti alimentari, bevande e tabacco",
  "46.41 - Commercio all'ingrosso di prodotti tessili",
  "46.42 - Commercio all'ingrosso di abbigliamento e di calzature",
  "46.43 - Commercio all'ingrosso di elettrodomestici, elettronica di consumo audio e video; articoli per fotografia, cinematografia e ottica",
  "46.44 - Commercio all'ingrosso di articoli di porcellana, di vetro e di prodotti per la pulizia",
  "46.45 - Commercio all'ingrosso di profumi e cosmetici",
  "46.46 - Commercio all'ingrosso di prodotti farmaceutici",
  "46.47 - Commercio all'ingrosso di mobili, tappeti e articoli per l'illuminazione",
  "46.48 - Commercio all'ingrosso di orologi e di gioielleria",
  "46.49 - Commercio all'ingrosso di altri beni di consumo",
  "46.51 - Commercio all'ingrosso di computer, apparecchiature informatiche periferiche e di software",
  "46.52 - Commercio all'ingrosso di apparecchiature elettroniche per telecomunicazioni e componenti elettronici",
  "46.61 - Commercio all'ingrosso di macchinari, attrezzature e forniture agricole",
  "46.62 - Commercio all'ingrosso di macchine utensili",
  "46.63 - Commercio all'ingrosso di macchinari per l'estrazione, l'edilizia e l'ingegneria civile",
  "46.64 - Commercio all'ingrosso di macchinari per l'industria tessile, di macchine per cucire e per maglieria",
  "46.65 - Commercio all'ingrosso di mobili per ufficio e negozi",
  "46.66 - Commercio all'ingrosso di altre macchine e attrezzature per ufficio",
  "46.69 - Commercio all'ingrosso di altri macchinari e attrezzature",
  "46.71 - Commercio all'ingrosso di combustibili solidi, liquidi, gassosi e di prodotti derivati",
  "46.72 - Commercio all'ingrosso di metalli e di minerali metalliferi",
  "46.73 - Commercio all'ingrosso di legname e di materiali da costruzione, apparecchi igienico-sanitari, vetro piano, vernici e colori",
  "46.74 - Commercio all'ingrosso di ferramenta, di apparecchi e accessori per impianti idraulici e di riscaldamento",
  "46.75 - Commercio all'ingrosso di prodotti chimici",
  "46.76 - Commercio all'ingrosso di altri prodotti intermedi",
  "46.77 - Commercio all'ingrosso di rottami e cascami",
  "46.90 - Commercio all'ingrosso non specializzato",
  '47.11 - Commercio al dettaglio in esercizi non specializzati con prevalenza di prodotti alimentari e bevande',
  '47.19 - Commercio al dettaglio in altri esercizi non specializzati',
  '47.21 - Commercio al dettaglio di frutta e verdura in esercizi specializzati',
  '47.22 - Commercio al dettaglio di carni e di prodotti a base di carne in esercizi specializzati',
  '47.23 - Commercio al dettaglio di pesci, crostacei e molluschi in esercizi specializzati',
  '47.24 - Commercio al dettaglio di pane, torte, dolciumi e confetteria in esercizi specializzati',
  '47.25 - Commercio al dettaglio di bevande in esercizi specializzati',
  '47.26 - Commercio al dettaglio di prodotti del tabacco in esercizi specializzati',
  '47.29 - Commercio al dettaglio di altri prodotti alimentari in esercizi specializzati',
  '47.30 - Commercio al dettaglio di carburante per autotrazione in esercizi specializzati',
  '47.41 - Commercio al dettaglio di computer, unità periferiche, software e attrezzature per ufficio in esercizi specializzati',
  '47.42 - Commercio al dettaglio di apparecchiature per le telecomunicazioni e la telefonia in esercizi specializzati',
  '47.43 - Commercio al dettaglio di apparecchiature audio e video in esercizi specializzati',
  '47.51 - Commercio al dettaglio di prodotti tessili in esercizi specializzati',
  '47.52 - Commercio al dettaglio di ferramenta, vernici, vetro piano e materiali da costruzione in esercizi specializzati',
  '47.53 - Commercio al dettaglio di tappeti, scendiletto e rivestimenti per pavimenti e pareti (moquette, linoleum) in esercizi specializzati',
  '47.54 - Commercio al dettaglio di elettrodomestici in esercizi specializzati',
  "47.59 - Commercio al dettaglio di mobili, di articoli per l'illuminazione e altri articoli per la casa in esercizi specializzati",
  '47.61 - Commercio al dettaglio di libri in esercizi specializzati',
  '47.62 - Commercio al dettaglio di giornali e articoli di cartoleria in esercizi specializzati',
  '47.63 - Commercio al dettaglio di registrazioni musicali e video in esercizi specializzati',
  '47.64 - Commercio al dettaglio di articoli sportivi in esercizi specializzati',
  '47.65 - Commercio al dettaglio di giochi e giocattoli in esercizi specializzati',
  '47.71 - Commercio al dettaglio di articoli di abbigliamento in esercizi specializzati',
  '47.72 - Commercio al dettaglio di calzature e articoli in pelle in esercizi specializzati',
  '47.73 - Commercio al dettaglio di medicinali in esercizi specializzati',
  '47.74 - Commercio al dettaglio di articoli medicali e ortopedici in esercizi specializzati',
  '47.75 - Commercio al dettaglio di cosmetici, di articoli di profumeria e di erboristeria in esercizi specializzati',
  '47.76 - Commercio al dettaglio di fiori, piante, semi, fertilizzanti, animali domestici e alimenti per animali domestici in esercizi specializzati',
  '47.77 - Commercio al dettaglio di orologi e articoli di gioielleria in esercizi specializzati',
  '47.78 - Commercio al dettaglio di altri prodotti (esclusi quelli di seconda mano) in esercizi specializzati',
  '47.79 - Commercio al dettaglio di articoli di seconda mano in negozi',
  '47.81 - Commercio al dettaglio ambulante di prodotti alimentari e bevande',
  '47.82 - Commercio al dettaglio ambulante di prodotti tessili, abbigliamento e calzature',
  '47.89 - Commercio al dettaglio ambulante di altri prodotti',
  '47.91 - Commercio al dettaglio per corrispondenza o attraverso internet',
  '47.99 - Altro commercio al dettaglio al di fuori di negozi, banchi o mercati',
  '49.10 - Trasporto ferroviario di passeggeri (interurbano)',
  '49.20 - Trasporto ferroviario di merci',
  '49.31 - Trasporto terrestre di passeggeri in aree urbane e suburbane',
  '49.32 - Trasporto con taxi, noleggio di autovetture con conducente',
  '49.39 - Altri trasporti terrestri di passeggeri nca',
  '49.41 - Trasporto di merci su strada',
  '49.42 - Servizi di trasloco',
  '49.50 - Trasporto mediante condotte',
  '50.10 - Trasporto marittimo e costiero di passeggeri',
  '50.20 - Trasporto marittimo e costiero di merci',
  "50.30 - Trasporto di passeggeri per vie d'acqua interne",
  "50.40 - Trasporto di merci per vie d'acqua interne",
  '51.10 - Trasporto aereo di passeggeri',
  '51.21 - Trasporto aereo di merci',
  '51.22 - Trasporto spaziale',
  '52.10 - Magazzinaggio e custodia',
  '52.21 - Attività dei servizi connessi ai trasporti terrestri',
  "52.22 - Attività dei servizi connessi al trasporto marittimo e per vie d'acqua",
  '52.23 - Attività dei servizi connessi al trasporto aereo',
  '52.24 - Movimentazione merci',
  '52.29 - Altre attività di supporto connesse ai trasporti',
  '53.10 - Attività postali con obbligo di servizio universale',
  '53.20 - Altre attività postali e di corriere senza obbligo di servizio universale',
  '55.10 - Alberghi e strutture simili',
  '55.20 - Alloggi per vacanze e altre strutture per brevi soggiorni',
  '55.30 - Aree di campeggio e aree attrezzate per camper e roulotte',
  '55.90 - Altri alloggi',
  '56.10 - Ristoranti e attività di ristorazione mobile',
  '56.21 - Fornitura di pasti preparati (catering per eventi)',
  '56.29 - Mense e catering continuativo su base contrattuale',
  '56.30 - Bar e altri esercizi simili senza cucina',
  '58.11 - Edizione di libri',
  '58.12 - Pubblicazione di elenchi e mailing list',
  '58.13 - Edizione di quotidiani',
  '58.14 - Edizione di riviste e periodici',
  '58.19 - Altre attività editoriali',
  '58.21 - Edizione di giochi per computer',
  '58.29 - Edizione di altri software',
  '59.11 - Attività di produzione cinematografica, di video e di programmi televisivi',
  '59.12 - Attività di post-produzione cinematografica, di video e di programmi televisivi',
  '59.13 - Attività di distribuzione cinematografica, di video e di programmi televisivi',
  '59.14 - Attività di proiezione cinematografica',
  '59.20 - Attività di registrazione sonora e di editoria musicale',
  '60.10 - Trasmissioni radiofoniche',
  '60.20 - Attività di programmazione e trasmissioni televisive',
  '61.10 - Telecomunicazioni fisse',
  '61.20 - Telecomunicazioni mobili',
  '61.30 - Telecomunicazioni satellitari',
  '61.90 - Altre attività di telecomunicazione',
  "62.01 - Produzione di software non connesso all'edizione",
  "62.02 - Consulenza nel settore delle tecnologie dell'informatica",
  '62.03 - Gestione di strutture informatizzate',
  "62.09 - Altre attività dei servizi connessi alle tecnologie dell'informatica",
  '63.11 - Elaborazione dei dati, hosting e attività connesse',
  '63.12 - Portali web',
  '63.91 - Attività delle agenzie di stampa',
  '63.99 - Altre attività dei servizi di informazione nca',
  '64.11 - Attività delle banche centrali',
  '64.19 - Altre intermediazioni monetarie',
  '64.20 - Attività delle società di partecipazione (holding)',
  '64.30 - Società fiduciarie, fondi e altre società simili',
  '64.91 - Leasing finanziario',
  '64.92 - Altre attività creditizie',
  '64.99 - Altre attività di servizi finanziari nca (escluse le assicurazioni e i fondi pensione)',
  '65.11 - Assicurazioni sulla vita',
  '65.12 - Assicurazioni diverse da quelle sulla vita',
  '65.20 - Riassicurazioni',
  '65.30 - Fondi pensione',
  '66.11 - Amministrazione di mercati finanziari',
  '66.12 - Attività di negoziazione di contratti relativi a titoli e merci',
  '66.19 - Altre attività ausiliarie dei servizi finanziari (escluse le assicurazioni e i fondi pensione)',
  '66.21 - Valutazione dei rischi e dei danni',
  '66.22 - Attività di agenti e mediatori di assicurazioni',
  '66.29 - Altre attività ausiliarie delle assicurazioni e dei fondi pensione',
  '66.30 - Attività di gestione dei fondi',
  '68.10 - Compravendita di beni immobili effettuata su beni propri',
  '68.20 - Affitto e gestione di immobili di proprietà o in leasing',
  '68.31 - Attività di mediazione immobiliare',
  '68.32 - Gestione di immobili per conto terzi',
  '69.10 - Attività degli studi legali e notarili',
  '69.20 - Contabilità, controllo e revisione contabile, consulenza in materia fiscale e del lavoro',
  '70.10 - Attività delle holding impegnate nelle attività gestionali (holding operative)',
  '70.21 - Pubbliche relazioni e comunicazione',
  '70.22 - Consulenza imprenditoriale e altra consulenza amministrativo-gestionale',
  '71.11 - Attività degli studi di architettura',
  "71.12 - Attività degli studi d'ingegneria ed altri studi tecnici",
  '71.20 - Collaudi ed analisi tecniche',
  '72.11 - Ricerca e sviluppo sperimentale nel campo delle biotecnologie',
  "72.19 - Altre attività di ricerca e sviluppo sperimentale nel campo delle scienze naturali e dell'ingegneria",
  '72.20 - Ricerca e sviluppo sperimentale nel campo delle scienze sociali e umanistiche',
  '73.11 - Agenzie pubblicitarie',
  '73.12 - Attività delle concessionarie e degli altri intermediari di servizi pubblicitari',
  '73.20 - Ricerche di mercato e sondaggi di opinione',
  '74.10 - Attività di design specializzate',
  '74.20 - Attività fotografiche',
  '74.30 - Traduzione e interpretariato',
  '74.90 - Altre attività professionali, scientifiche e tecniche nca',
  '75.00 - Servizi veterinari',
  '77.11 - Noleggio di autovetture ed autoveicoli leggeri',
  '77.12 - Noleggio di autocarri e di altri veicoli pesanti',
  '77.21 - Noleggio di attrezzature sportive e ricreative',
  '77.22 - Noleggio di videocassette e dischi',
  '77.29 - Noleggio di altri beni per uso personale e domestico (escluse le attrezzature sportive e ricreative)',
  '77.31 - Noleggio di macchine e attrezzature agricole',
  '77.32 - Noleggio di macchine e attrezzature per lavori edili e di genio civile',
  '77.33 - Noleggio di macchine e attrezzature per ufficio (inclusi i computer)',
  '77.34 - Noleggio di mezzi di trasporto marittimo e fluviale',
  '77.35 - Noleggio di mezzi di trasporto aereo',
  '77.39 - Noleggio di altre macchine, attrezzature e beni materiali nca',
  '77.40 - Concessione dei diritti di sfruttamento di proprietà intellettuale e prodotti simili (escluse le opere protette dal copyright)',
  '78.10 - Attività di agenzie di collocamento',
  '78.20 - Attività delle agenzie di lavoro temporaneo (interinale)',
  '78.30 - Altre attività di fornitura e gestione di risorse umane',
  '79.11 - Attività delle agenzie di viaggio',
  '79.12 - Attività dei tour operator',
  '79.90 - Altri servizi di prenotazione e altre attività di assistenza turistica non svolte dalle agenzie di viaggio',
  '80.10 - Servizi di vigilanza privata',
  '80.20 - Servizi connessi ai sistemi di vigilanza',
  '80.30 - Servizi investigativi privati',
  '81.10 - Servizi integrati di gestione agli edifici',
  '81.21 - Pulizia generale (non specializzata) di edifici',
  '81.22 - Attività di pulizia specializzata di edifici e di impianti e macchinari industriali',
  '81.29 - Altre attività di pulizia',
  '81.30 - Cura e manutenzione del paesaggio',
  "82.11 - Servizi integrati di supporto per le funzioni d'ufficio",
  "82.19 - Servizi di fotocopiatura, preparazione di documenti e altre attività di supporto specializzate per le funzioni d'ufficio",
  '82.20 - Attività dei call center',
  '82.30 - Organizzazione di convegni e fiere',
  '82.91 - Attività di agenzie di recupero crediti; agenzie di informazioni commerciali',
  '82.92 - Attività di imballaggio e confezionamento per conto terzi',
  '82.99 - Altri servizi di supporto alle imprese nca',
  '84.11 - Attività generali di amministrazione pubblica',
  "84.12 - Regolamentazione delle attività relative alla fornitura di servizi di assistenza sanitaria, dell'istruzione, di servizi culturali e ad altri servizi sociali(esclusa l'assicurazione sociale obbligatoria)",
  '84.13 - Regolamentazione delle attività che contribuiscono ad una più efficiente gestione delle attività economiche',
  '84.21 - Affari esteri',
  '84.22 - Difesa nazionale',
  '84.23 - Giustizia ed attività giudiziarie',
  '84.24 - Ordine pubblico e sicurezza nazionale',
  '84.25 - Attività dei vigili del fuoco e della protezione civile',
  '84.30 - Assicurazione sociale obbligatoria',
  '85.10 - Istruzione prescolastica',
  '85.20 - Istruzione primaria',
  '85.31 - Istruzione secondaria di formazione generale',
  '85.32 - Istruzione secondaria tecnica e professionale',
  '85.41 - Istruzione post-secondaria non universitaria',
  '85.42 - Istruzione universitaria e post-universitaria; accademie e conservatori',
  '85.51 - Corsi sportivi e ricreativi',
  '85.52 - Formazione culturale',
  '85.53 - Attività delle scuole guida',
  '85.59 - Servizi di istruzione nca',
  "85.60 - Attività di supporto all'istruzione",
  '86.10 - Servizi ospedalieri',
  '86.21 - Servizi degli studi medici di medicina generale',
  '86.22 - Servizi degli studi medici specialistici',
  '86.23 - Attività degli studi odontoiatrici',
  '86.90 - Altri servizi di assistenza sanitaria',
  '87.10 - Strutture di assistenza infermieristica residenziale',
  '87.20 - Strutture di assistenza residenziale per persone affette da ritardi mentali, disturbi mentali o che abusano di sostanze stupefacenti',
  '87.30 - Strutture di assistenza residenziale per anziani e disabili',
  '87.90 - Altre strutture di assistenza sociale residenziale',
  '88.10 - Assistenza sociale non residenziale per anziani e disabili',
  '88.91 - Servizi di asili nido; assistenza diurna per minori disabili',
  '88.99 - Altre attività di assistenza sociale non residenziale nca',
  '90.01 - Rappresentazioni artistiche',
  '90.02 - Attività di supporto alle rappresentazioni artistiche',
  '90.03 - Creazioni artistiche e letterarie',
  '90.04 - Gestione di strutture artistiche',
  '91.01 - Attività di biblioteche ed archivi',
  '91.02 - Attività di musei',
  '91.03 - Gestione di luoghi e monumenti storici e attrazioni simili',
  '91.04 - Attività degli orti botanici, dei giardini zoologici e delle riserve naturali',
  '92.00 - Attività riguardanti le lotterie, le scommesse, le case da gioco',
  '93.11 - Gestione di impianti sportivi',
  '93.12 - Attività di club sportivi',
  '93.13 - Palestre',
  '93.19 - Altre attività sportive',
  '93.21 - Parchi di divertimento e parchi tematici',
  '93.29 - Altre attività ricreative e di divertimento',
  '94.11 - Attività di organizzazioni economiche e di datori di lavoro',
  '94.12 - Attività di organizzazioni associative professionali',
  '94.20 - Attività dei sindacati di lavoratori dipendenti',
  "94.91 - Attività delle organizzazioni religiose nell'esercizio del culto",
  '94.92 - Attività dei partiti e delle associazioni politiche',
  '94.99 - Attività di altre organizzazioni associative nca',
  '95.11 - Riparazione di computer e periferiche',
  '95.12 - Riparazione di apparecchiature per le comunicazioni',
  '95.21 - Riparazione di prodotti elettronici di consumo audio e video',
  '95.22 - Riparazione di elettrodomestici e di articoli per la casa e il giardinaggio',
  '95.23 - Riparazione di calzature e articoli da viaggio',
  '95.24 - Riparazione di mobili e di oggetti di arredamento; laboratori di tappezzeria',
  '95.25 - Riparazione di orologi e di gioielli',
  '95.29 - Riparazione di altri beni per uso personale e per la casa',
  '96.01 - Lavanderia e pulitura di articoli tessili e pelliccia',
  '96.02 - Servizi dei parrucchieri e di altri trattamenti estetici',
  '96.03 - Servizi di pompe funebri e attività connesse',
  '96.04 - Servizi dei centri per il benessere fisico',
  '96.09 - Attività di servizi per la persona nca',
  '97.00 - Attività di famiglie e convivenze come datori di lavoro per personale domestico',
  '98.10 - Produzione di beni indifferenziati per uso proprio da parte di famiglie e convivenze',
  '98.20 - Produzione di servizi indifferenziati per uso proprio da parte di famiglie e convivenze',
  '99.00 - Organizzazioni ed organismi extraterritoriali'
];

export enum corporateFormValues {
  CONDOMINIUM = 'C. - Condominio',
  //CONDOMINIUM = 'Condominio',
  SPA = 'S.P.A. - Società per azioni',
  USUFRUCTUARY = 'I.I. - Impresa individuale',
  SRL = 'S.R.L. - Società a responsabilità limitata',
  SAS = 'S.A.S. - Società in accomandita semplice',
  SCPA = 'S.C.P.A. - Società cooperativa per azioni',
  SCRL = 'S.C.R.L. - Società cooperativa a responsabilità limitata',
  SNC = 'S.N.C. - Società in nome collettivo',
  SRLS = 'S.R.L.S. - Società a responsabilità limitata semplificata',
  SAPA = 'S.A.P.A. - Società in accomandita per azioni',
  EP = 'E.P. - Ente pubblico',
  LP = 'L.P. - Libero professionista',
  SS = 'S.S. - Società semplice',
  ONLUS = 'O.N.L.U.S. - Organizzazione Non Lucrativa di Utilità Sociale',
  OTHER = 'ALTRO - Consorzio, Associazione, Fondazione, Enti privati'
}
export enum corporateFormViewValues {
  CONDOMINIUM = 'Condominio',
  SPA = 'Società per azioni',
  USUFRUCTUARY = 'Impresa individuale',
  SRL = 'Società a responsabilità limitata',
  SAS = 'Società in accomandita semplice',
  SCPA = 'Società cooperativa per azioni',
  SCRL = 'Società cooperativa a responsabilità limitata',
  SNC = 'Società in nome collettivo',
  SRLS = 'Società a responsabilità limitata semplificata',
  SAPA = 'Società in accomandita per azioni',
  EP = 'Ente pubblico',
  LP = 'Libero professionista',
  SS = 'Società semplice',
  ONLUS = 'Organizzazione Non Lucrativa di Utilità Sociale',
  OTHER = 'Consorzio, Associazione, Fondazione, Enti privati'
}

export enum ProfileRoles {
  OPERATORE_DI_VENDITA = 1,
  TEAM_LEADER = 2,
  IT_IREN = 6,
  SUPERVISOR_BOE_PROCESSI = 7,
  OPERATORE_BOE = 8,
  SUPERVISOR_PROCESSO_IREN = 5,
  CHANNEL_ACCOUNT = 4,
  EDITOR = 9,
  SUPERVISOR_BO_PROCESSO = 3
}

export const SetInfomrativoLumineaMapping = [
  { path: 'assets/pdf/4.Set Informativo Luminea Family.pdf', uatSku: 'SKU-A-00000043', prodSku: 'SKU-A-00000542' },
  { path: 'assets/pdf/4.Set Informativo Luminea Family Plus.pdf', uatSku: 'SKU-A-00000044', prodSku: 'SKU-A-00000543' },
  { path: 'assets/pdf/4.Set Informativo Luminea Infortuni.pdf', uatSku: 'SKU-A-00000042', prodSku: 'SKU-A-00000541' },
  { path: 'assets/pdf/4.Set Informativo Luminea Universo Casa Plus 2609.pdf', uatSku: 'SKU-A-10000556', prodSku: 'SKU-A-00000532' },
  { path: 'assets/pdf/4.Set Informativo Luminea Universo Casa 2609.pdf', uatSku: 'SKU-A-10000555', prodSku: 'SKU-A-00000531' },
  { path: 'assets/pdf/4.Set Informativo Luminea Pronto Casa 2609.pdf', uatSku: 'SKU-A-00005554', prodSku: 'SKU-A-00000530' }
];

export enum SpecialKeys {
  CSVUTF8 = '77u/'
}

export const DateFormatBe = 'yyyy-MM-dd';
export const DateFormatFe = 'dd-MM-yyyy';

export const FormatReferenceDateVerti = 'yyyyMMdd';

export const FormatMatriculationDateVerti = 'yyyyMM';

export const formatDateForVertiUTC = function (dataISO: string) {
  // Creare un oggetto Date dalla stringa ISO
  var data = new Date(dataISO);

  // Estrai i componenti della data
  var anno = data.getUTCFullYear();
  var mese = ('0' + (data.getUTCMonth() + 1)).slice(-2);
  var giorno = ('0' + data.getUTCDate()).slice(-2);
  var ore = ('0' + data.getUTCHours()).slice(-2);
  var minuti = ('0' + data.getUTCMinutes()).slice(-2);
  var secondi = ('0' + data.getUTCSeconds()).slice(-2);
  var millisecondi = ('00' + data.getUTCMilliseconds()).slice(-3);
  var offset = data.getTimezoneOffset();
  var offsetString = (offset < 0 ? '+' : '-') + ('0' + Math.abs(offset / 60)).slice(-2) + ('0' + (offset % 60)).slice(-2);

  // Costruisci la stringa nel formato desiderato
  var dataFormattata = `${anno}-${mese}-${giorno}T${ore}:${minuti}:${secondi}.${millisecondi}${offsetString}`;

  return dataFormattata;
};

export const formatDateFromVerti = function (notCompletedDate: string | number) {
  if (typeof notCompletedDate === 'string') {
    const anno = notCompletedDate.slice(0, 4);
    const mese = notCompletedDate.slice(4);

    const data = `${anno}-${mese}-01T00:00:00.000Z`;

    return data;
  } else if (typeof notCompletedDate === 'number' && notCompletedDate.toString().length > 3) {
    const data = `${notCompletedDate}-01-01T00:00:00.000Z`;
    return data;
  } else {
    const data = `20${notCompletedDate}-01-01T00:00:00.000Z`;
    return data;
  }
};

export const notificationType = [
  {
    key: 'PRESENCE_RESIDUE',
    value: '',
    route: '',
    label: 'PRESENZE RESIDUE'
  },
  {
    key: 'HOT_LEAD_DISPATCH',
    value: '',
    route: '/my-area/lead-management/download-hot-lead',
    label: 'DOWNLOAD LEAD CALDI'
  },
  {
    key: 'COLD_LEAD_DISPATCH',
    value: '',
    route: '/my-area/lead-management/download-cold-lead',
    label: 'DOWNLOAD LEAD FREDDI'
  },
  {
    key: 'RCA_LEAD_DISPATCH',
    value: '',
    route: '/my-area/lead-management/download-plate-lead',
    label: 'DOWNLOAD LEAD TARGHE'
  },
  {
    key: 'REPORT_RCA_LEAD_AVAILABLE',
    value: '',
    route: '/my-area/lead-management/plate-lead-kpi/estrazione-esiti-targhe',
    label: 'REPORT RC TARGHE'
  },
  {
    key: 'REPORT_HOT_LEAD_AVAILABLE',
    value: 'estrazioni-esiti-caldi',
    route: '/my-area/lead-management/hot-lead-kpi/estrazione-esiti-caldi',
    label: 'REPORT LEAD CALDI'
  },
  {
    key: 'REPORT_COLD_LEAD_AVAILABLE',
    value: 'estrazioni-esiti-freddi',
    route: '/my-area/lead-management/cold-lead-kpi/results-extraction',
    label: 'REPORT LEAD FREDDI'
  },
  {
    key: 'REPORT_AVAILABLE',
    value: 'download-report',
    route: '/my-area/kpi-monitoring/download-report',
    label: 'REPORT KPI SALES'
  },
  {
    key: 'LEAD_TO_ENRICH',
    value: '',
    route: '/my-area/lead-management/download-plate-lead',
    label: 'ENRICHMENT RC TARGHE'
  },
  {
    key: 'ASSEGNAZIONE_PRATICA_LUMINEA',
    value: '',
    route: '/my-area/tracking-contracts-luminea/sales/draft',
    label: 'PRATICA LUMINEA ASSEGNATA'
  },
  {
    key: 'ROTTURA_INJECTION_HOT_LEAD',
    value: '',
    route: '',
    label: 'ROTTURA LEAD CALDI INJECTION'
  },
  {
    key: 'TRAINING',
    value: '',
    route: '/my-area/training',
    label: 'FORMAZIONE'
  }
];

export enum ClientType {
  BUSINESS = 'Business',
  RESIDENZIALE = 'Residenziale',
  CONDOMINIO = 'Condominio'
}

const serializeArr = (arr: any[]) => {
  return arr.map(obj => {
    return JSON.stringify(obj);
  });
};
export const arrayUnique = (arr: any[]) => {
  let objects = serializeArr(arr);
  let unique = [...new Set(objects)];
  return unique.map(str => {
    return JSON.parse(str);
  });
};

export function mergeArraysUniqueByKey_OLD(array1: any[], array2: any[], key: string): any[] {
  const commercialActionsInArray1 = new Set(array1.map(obj => obj[key]));
  const mergedArray: any[] = array2.filter(obj => commercialActionsInArray1.has(obj[key]));

  return mergedArray;
}

export function mergeArraysUniqueByKey(array1: any[], array2: any[], key: string): any[] {
  const map = new Map();

  // Prima, aggiungiamo tutti gli elementi di array1
  array1.forEach(item => map.set(item[key], item));

  // Poi, aggiungiamo gli elementi di array2 solo se non esistono già in array1
  array2.forEach(item => {
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  });

  return Array.from(map.values());
}

export function getDatesOfTheWeek() {
  const today = new Date(); // crea un oggetto Date rappresentante la data corrente
  const dayOfWeek = today.getDay(); // ottiene il giorno della settimana corrente (0 = Domenica, 1 = Lunedì, ecc.)
  const datesOfWeek = []; // inizializza un array vuoto
  for (let i = 0; i < 7; i++) {
    const date = new Date(today); // crea un nuovo oggetto Date dalla data corrente
    const diff = i - dayOfWeek; // calcola la differenza tra il giorno della settimana corrente e il giorno che si vuole creare
    date.setDate(today.getDate() + diff); // imposta la data come la data corrente più la differenza calcolata
    datesOfWeek.push(date); // aggiunge la data all'array
  }
  return datesOfWeek;
}

//CF CALCULATOR CONSTANTS

export const tavola_mesi = ['A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'S', 'T'];
export const alternative_tavola_mesi = ['A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'I', 'T'];

export const tavola_omocodie = ['L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'];

export const tavola_carattere_di_controllo_valore_caratteri_dispari = {
  0: 1,
  1: 0,
  2: 5,
  3: 7,
  4: 9,
  5: 13,
  6: 15,
  7: 17,
  8: 19,
  9: 21,
  A: 1,
  B: 0,
  C: 5,
  D: 7,
  E: 9,
  F: 13,
  G: 15,
  H: 17,
  I: 19,
  J: 21,
  K: 2,
  L: 4,
  M: 18,
  N: 20,
  O: 11,
  P: 3,
  Q: 6,
  R: 8,
  S: 12,
  T: 14,
  U: 16,
  V: 10,
  W: 22,
  X: 25,
  Y: 24,
  Z: 23
};

export const codiciCatastaliStranieri = [
  { 'viewValue': 'ALBANIA', 'value': 'Z100' },
  { 'viewValue': 'ANDORRA', 'value': 'Z101' },
  { 'viewValue': 'AUSTRIA', 'value': 'Z102' },
  { 'viewValue': 'BELGIO', 'value': 'Z103' },
  { 'viewValue': 'BULGARIA', 'value': 'Z104' },
  { 'viewValue': 'CECOSLOVACCHIA', 'value': 'Z105' },
  { 'viewValue': 'STATO CITTA DEL VATICANO', 'value': 'Z106' },
  { 'viewValue': 'DANIMARCA', 'value': 'Z107' },
  { 'viewValue': 'ISOLE FAER OER', 'value': 'Z108' },
  { 'viewValue': 'FINLANDIA', 'value': 'Z109' },
  { 'viewValue': 'FRANCIA', 'value': 'Z110' },
  { 'viewValue': 'GERMANIA REPUBBLICA DEMOCRATICA', 'value': 'Z111' },
  { 'viewValue': 'GERMANIA', 'value': 'Z112' },
  { 'viewValue': 'GIBILTERRA', 'value': 'Z113' },
  { 'viewValue': 'REGNO UNITO', 'value': 'Z114' },
  { 'viewValue': 'GRECIA', 'value': 'Z115' },
  { 'viewValue': 'IRLANDA', 'value': 'Z116' },
  { 'viewValue': 'ISLANDA', 'value': 'Z117' },
  { 'viewValue': 'IUGOSLAVIA', 'value': 'Z118' },
  { 'viewValue': 'LIECHTENSTEIN', 'value': 'Z119' },
  { 'viewValue': 'LUSSEMBURGO', 'value': 'Z120' },
  { 'viewValue': 'MALTA', 'value': 'Z121' },
  { 'viewValue': 'ISOLA DI MAN', 'value': 'Z122' },
  { 'viewValue': 'MONACO', 'value': 'Z123' },
  { 'viewValue': 'NORMANNE (ISOLE) O ISOLE DEL CANALE', 'value': 'Z124' },
  { 'viewValue': 'NORVEGIA', 'value': 'Z125' },
  { 'viewValue': 'PAESI BASSI', 'value': 'Z126' },
  { 'viewValue': 'POLONIA', 'value': 'Z127' },
  { 'viewValue': 'PORTOGALLO', 'value': 'Z128' },
  { 'viewValue': 'ROMANIA', 'value': 'Z129' },
  { 'viewValue': 'SAN MARINO', 'value': 'Z130' },
  { 'viewValue': 'SPAGNA', 'value': 'Z131' },
  { 'viewValue': 'SVEZIA', 'value': 'Z132' },
  { 'viewValue': 'SVIZZERA', 'value': 'Z133' },
  { 'viewValue': 'UNGHERIA', 'value': 'Z134' },
  { 'viewValue': 'UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE', 'value': 'Z135' },
  { 'viewValue': 'GEORGIA', 'value': 'Z136' },
  { 'viewValue': 'ARMENIA', 'value': 'Z137' },
  { 'viewValue': 'UCRAINA', 'value': 'Z138' },
  { 'viewValue': 'BIELORUSSIA', 'value': 'Z139' },
  { 'viewValue': 'MOLDOVA', 'value': 'Z140' },
  { 'viewValue': 'AZERBAIGIAN', 'value': 'Z141' },
  { 'viewValue': 'KIRGHIZISTAN', 'value': 'Z142' },
  { 'viewValue': 'UZBEKISTAN', 'value': 'Z143' },
  { 'viewValue': 'ESTONIA', 'value': 'Z144' },
  { 'viewValue': 'LETTONIA', 'value': 'Z145' },
  { 'viewValue': 'LITUANIA', 'value': 'Z146' },
  { 'viewValue': 'TAGIKISTAN', 'value': 'Z147' },
  { 'viewValue': 'MACEDONIA DEL NORD', 'value': 'Z148' },
  { 'viewValue': 'CROAZIA', 'value': 'Z149' },
  { 'viewValue': 'SLOVENIA', 'value': 'Z150' },
  { 'viewValue': 'TURKMENISTAN', 'value': 'Z151' },
  { 'viewValue': 'KAZAKHSTAN', 'value': 'Z152' },
  { 'viewValue': 'BOSNIA-ERZEGOVINA', 'value': 'Z153' },
  { 'viewValue': 'FEDERAZIONE RUSSA', 'value': 'Z154' },
  { 'viewValue': 'SLOVACCHIA', 'value': 'Z155' },
  { 'viewValue': 'REPUBBLICA CECA', 'value': 'Z156' },
  { 'viewValue': 'SERBIA E MONTENEGRO', 'value': 'Z157' },
  { 'viewValue': 'SERBIA', 'value': 'Z158' },
  { 'viewValue': 'MONTENEGRO', 'value': 'Z159' },
  { 'viewValue': 'KOSOVO', 'value': 'Z160' },
  { 'viewValue': 'ALGERIA', 'value': 'Z301' },
  { 'viewValue': 'ANGOLA', 'value': 'Z302' },
  { 'viewValue': 'BASUTOLAND', 'value': 'Z303' },
  { 'viewValue': 'BECIUANIA', 'value': 'Z304' },
  { 'viewValue': 'BENIN', 'value': 'Z314' },
  { 'viewValue': 'BOPHUTHATSWANA', 'value': 'Z364' },
  { 'viewValue': 'BOTSWANA', 'value': 'Z358' },
  { 'viewValue': 'BURKINA FASO', 'value': 'Z354' },
  { 'viewValue': 'BURUNDI', 'value': 'Z305' },
  { 'viewValue': 'CAMERUN', 'value': 'Z306' },
  { 'viewValue': 'CAPO VERDE', 'value': 'Z307' },
  { 'viewValue': 'CIAD', 'value': 'Z309' },
  { 'viewValue': 'CISKEI', 'value': 'Z367' },
  { 'viewValue': 'COMORE', 'value': 'Z310' },
  { 'viewValue': 'CONGO', 'value': 'Z311' },
  { 'viewValue': 'CONGO BELGA', 'value': 'Z370' },
  { 'viewValue': "COSTA D'AVORIO", 'value': 'Z313' },
  { 'viewValue': 'EGITTO', 'value': 'Z336' },
  { 'viewValue': 'ERITREA', 'value': 'Z368' },
  { 'viewValue': 'ESWATINI', 'value': 'Z349' },
  { 'viewValue': 'ETIOPIA', 'value': 'Z315' },
  { 'viewValue': 'GABON', 'value': 'Z316' },
  { 'viewValue': 'GAMBIA', 'value': 'Z317' },
  { 'viewValue': 'GHANA', 'value': 'Z318' },
  { 'viewValue': 'GIBUTI', 'value': 'Z361' },
  { 'viewValue': 'GUINEA', 'value': 'Z319' },
  { 'viewValue': 'GUINEA BISSAU', 'value': 'Z320' },
  { 'viewValue': 'GUINEA EQUATORIALE', 'value': 'Z321' },
  { 'viewValue': 'IFNI', 'value': 'Z323' },
  { 'viewValue': 'KENYA', 'value': 'Z322' },
  { 'viewValue': 'LESOTHO', 'value': 'Z359' },
  { 'viewValue': 'LIBERIA', 'value': 'Z325' },
  { 'viewValue': 'LIBIA', 'value': 'Z326' },
  { 'viewValue': 'MADAGASCAR', 'value': 'Z327' },
  { 'viewValue': 'MALAWI', 'value': 'Z328' },
  { 'viewValue': 'MALI', 'value': 'Z329' },
  { 'viewValue': 'MAROCCO', 'value': 'Z330' },
  { 'viewValue': 'MAURITANIA', 'value': 'Z331' },
  { 'viewValue': 'MAURITIUS', 'value': 'Z332' },
  { 'viewValue': 'MAYOTTE', 'value': 'Z360' },
  { 'viewValue': 'MOZAMBICO', 'value': 'Z333' },
  { 'viewValue': 'NAMIBIA', 'value': 'Z300' },
  { 'viewValue': 'NIGER', 'value': 'Z334' },
  { 'viewValue': 'NIGERIA', 'value': 'Z335' },
  { 'viewValue': 'NYASALAND', 'value': 'Z369' },
  { 'viewValue': 'REPUBBLICA CENTRAFRICANA', 'value': 'Z308' },
  { 'viewValue': 'REPUBBLICA DEMOCRATICA DEL CONGO', 'value': 'Z312' },
  { 'viewValue': 'RIUNIONE', 'value': 'Z324' },
  { 'viewValue': 'RUANDA', 'value': 'Z338' },
  { 'viewValue': 'SAHARA MERIDIONALE', 'value': 'Z362' },
  { 'viewValue': 'SAHARA SETTENTRIONALE', 'value': 'Z363' },
  { 'viewValue': 'SAHARA SPAGNOLO', 'value': 'Z339' },
  { 'viewValue': "SANT'ELENA", 'value': 'Z340' },
  { 'viewValue': "SAO TOME' E PRINCIPE", 'value': 'Z341' },
  { 'viewValue': 'SENEGAL', 'value': 'Z343' },
  { 'viewValue': 'SEYCHELLES', 'value': 'Z342' },
  { 'viewValue': 'SIERRA LEONE', 'value': 'Z344' },
  { 'viewValue': 'SOMALIA', 'value': 'Z345' },
  { 'viewValue': 'SUD AFRICA', 'value': 'Z347' },
  { 'viewValue': 'SUD SUDAN', 'value': 'Z907' },
  { 'viewValue': 'SUDAN', 'value': 'Z348' },
  { 'viewValue': 'TANGANICA', 'value': 'Z350' },
  { 'viewValue': 'TANZANIA', 'value': 'Z357' },
  { 'viewValue': 'TERRITORIO FRANCESE DEGLI AFAR E DEGLI ISSA', 'value': 'Z346' },
  { 'viewValue': 'TOGO', 'value': 'Z351' },
  { 'viewValue': 'TRANSKEI', 'value': 'Z365' },
  { 'viewValue': 'TUNISIA', 'value': 'Z352' },
  { 'viewValue': 'UGANDA', 'value': 'Z353' },
  { 'viewValue': 'VENDA', 'value': 'Z366' },
  { 'viewValue': 'ZAMBIA', 'value': 'Z355' },
  { 'viewValue': 'ZANZIBAR', 'value': 'Z356' },
  { 'viewValue': 'ZIMBABWE', 'value': 'Z337' },
  { 'viewValue': 'AFGHANISTAN', 'value': 'Z200' },
  { 'viewValue': 'ARABIA MERIDIONALE FEDERAZIONE', 'value': 'Z201' },
  { 'viewValue': 'ARABIA MERIDIONALE PROTETTORATO', 'value': 'Z202' },
  { 'viewValue': 'ARABIA SAUDITA', 'value': 'Z203' },
  { 'viewValue': 'ARMENIA', 'value': 'Z252' },
  { 'viewValue': 'AZERBAIGIAN', 'value': 'Z253' },
  { 'viewValue': 'BAHREIN', 'value': 'Z204' },
  { 'viewValue': 'BANGLADESH', 'value': 'Z249' },
  { 'viewValue': 'BHUTAN', 'value': 'Z205' },
  { 'viewValue': 'BRUNEI', 'value': 'Z207' },
  { 'viewValue': 'CAMBOGIA', 'value': 'Z208' },
  { 'viewValue': 'CIPRO', 'value': 'Z211' },
  { 'viewValue': 'COCOS (ISOLE)', 'value': 'Z212' },
  { 'viewValue': 'EMIRATI ARABI UNITI', 'value': 'Z215' },
  { 'viewValue': 'FILIPPINE', 'value': 'Z216' },
  { 'viewValue': 'GAZA (TERRITORIO DI)', 'value': 'Z218' },
  { 'viewValue': 'GEORGIA', 'value': 'Z254' },
  { 'viewValue': 'GERUSALEMME', 'value': 'Z260' },
  { 'viewValue': 'GIAPPONE', 'value': 'Z219' },
  { 'viewValue': 'GIORDANIA', 'value': 'Z220' },
  { 'viewValue': 'HONG KONG', 'value': 'Z221' },
  { 'viewValue': 'INDIA', 'value': 'Z222' },
  { 'viewValue': 'INDONESIA', 'value': 'Z223' },
  { 'viewValue': 'IRAN', 'value': 'Z224' },
  { 'viewValue': 'IRAQ', 'value': 'Z225' },
  { 'viewValue': 'ISRAELE', 'value': 'Z226' },
  { 'viewValue': 'KAZAKHSTAN', 'value': 'Z255' },
  { 'viewValue': 'KIRGHIZISTAN', 'value': 'Z256' },
  { 'viewValue': 'KUWAIT', 'value': 'Z227' },
  { 'viewValue': 'LAOS', 'value': 'Z228' },
  { 'viewValue': 'LIBANO', 'value': 'Z229' },
  { 'viewValue': 'MACAO', 'value': 'Z231' },
  { 'viewValue': 'MALAYSIA', 'value': 'Z230' },
  { 'viewValue': 'MALDIVE', 'value': 'Z232' },
  { 'viewValue': 'MONGOLIA', 'value': 'Z233' },
  { 'viewValue': 'MYANMAR', 'value': 'Z206' },
  { 'viewValue': 'NEPAL', 'value': 'Z234' },
  { 'viewValue': 'OMAN', 'value': 'Z235' },
  { 'viewValue': 'PAKISTAN', 'value': 'Z236' },
  { 'viewValue': 'QATAR', 'value': 'Z237' },
  { 'viewValue': 'REPUBBLICA DI COREA', 'value': 'Z213' },
  { 'viewValue': 'REPUBBLICA POPOLARE CINESE', 'value': 'Z210' },
  { 'viewValue': 'REPUBBLICA POPOLARE DEMOCRATICA DI COREA', 'value': 'Z214' },
  { 'viewValue': 'RYUKYU (ISOLE)', 'value': 'Z238' },
  { 'viewValue': 'SIKKIM', 'value': 'Z239' },
  { 'viewValue': 'SINGAPORE', 'value': 'Z248' },
  { 'viewValue': 'SIRIA', 'value': 'Z240' },
  { 'viewValue': 'SRI LANKA', 'value': 'Z209' },
  { 'viewValue': 'TAGIKISTAN', 'value': 'Z257' },
  { 'viewValue': 'TAIWAN', 'value': 'Z217' },
  { 'viewValue': "TERRITORI DELL'AUTONOMIA PALESTINESE", 'value': 'Z161' },
  { 'viewValue': 'THAILANDIA', 'value': 'Z241' },
  { 'viewValue': 'TIMOR ORIENTALE', 'value': 'Z242' },
  { 'viewValue': 'TURCHIA', 'value': 'Z243' },
  { 'viewValue': 'TURKMENISTAN', 'value': 'Z258' },
  { 'viewValue': 'UZBEKISTAN', 'value': 'Z259' },
  { 'viewValue': 'VIETNAM', 'value': 'Z251' },
  { 'viewValue': 'VIETNAM DEL NORD', 'value': 'Z245' },
  { 'viewValue': 'VIETNAM DEL SUD', 'value': 'Z244' },
  { 'viewValue': 'YEMEN', 'value': 'Z246' },
  { 'viewValue': 'YEMEN REPUBBLICA DEMOCRATICA POPOLARE', 'value': 'Z250' },
  { 'viewValue': 'BERMUDA', 'value': 'Z400' },
  { 'viewValue': 'CANADA', 'value': 'Z401' },
  { 'viewValue': 'GROENLANDIA', 'value': 'Z402' },
  { 'viewValue': 'SAINT PIERRE E MIQUELON', 'value': 'Z403' },
  { 'viewValue': "STATI UNITI D'AMERICA", 'value': 'Z404' },
  { 'viewValue': 'ANGUILLA', 'value': 'Z529' },
  { 'viewValue': 'ANTIGUA E BARBUDA', 'value': 'Z532' },
  { 'viewValue': 'ANTILLE BRITANNICHE', 'value': 'Z500' },
  { 'viewValue': 'ANTILLE BRITANNICHE', 'value': 'Z523' },
  { 'viewValue': 'ANTILLE BRITANNICHE', 'value': 'Z521' },
  { 'viewValue': 'ANTILLE OLANDESI', 'value': 'Z501' },
  { 'viewValue': 'BAHAMAS', 'value': 'Z502' },
  { 'viewValue': 'BARBADOS', 'value': 'Z522' },
  { 'viewValue': 'BELIZE', 'value': 'Z512' },
  { 'viewValue': 'COSTA RICA', 'value': 'Z503' },
  { 'viewValue': 'CUBA', 'value': 'Z504' },
  { 'viewValue': 'DOMINICA', 'value': 'Z526' },
  { 'viewValue': 'EL SALVADOR', 'value': 'Z506' },
  { 'viewValue': 'GIAMAICA', 'value': 'Z507' },
  { 'viewValue': 'GRENADA', 'value': 'Z524' },
  { 'viewValue': 'GUADALUPA', 'value': 'Z508' },
  { 'viewValue': 'GUATEMALA', 'value': 'Z509' },
  { 'viewValue': 'HAITI', 'value': 'Z510' },
  { 'viewValue': 'HONDURAS', 'value': 'Z511' },
  { 'viewValue': 'ISOLE CAYMAN', 'value': 'Z530' },
  { 'viewValue': 'ISOLE TURKS E CAICOS', 'value': 'Z519' },
  { 'viewValue': 'ISOLE VERGINI BRITANNICHE', 'value': 'Z525' },
  { 'viewValue': 'MARTINICA', 'value': 'Z513' },
  { 'viewValue': 'MESSICO', 'value': 'Z514' },
  { 'viewValue': 'MONTSERRAT', 'value': 'Z531' },
  { 'viewValue': 'NICARAGUA', 'value': 'Z515' },
  { 'viewValue': 'PANAMA', 'value': 'Z516' },
  { 'viewValue': 'PANAMA ZONA DEL CANALE', 'value': 'Z517' },
  { 'viewValue': 'PUERTO RICO', 'value': 'Z518' },
  { 'viewValue': 'REPUBBLICA DOMINICANA', 'value': 'Z505' },
  { 'viewValue': 'SAINT KITTS E NEVIS', 'value': 'Z533' },
  { 'viewValue': 'SAINT LUCIA', 'value': 'Z527' },
  { 'viewValue': 'SAINT VINCENT E GRENADINE', 'value': 'Z528' },
  { 'viewValue': 'VERGINI AMERICANE (ISOLE)', 'value': 'Z520' },
  { 'viewValue': 'ARGENTINA', 'value': 'Z600' },
  { 'viewValue': 'BOLIVIA', 'value': 'Z601' },
  { 'viewValue': 'BRASILE', 'value': 'Z602' },
  { 'viewValue': 'CILE', 'value': 'Z603' },
  { 'viewValue': 'COLOMBIA', 'value': 'Z604' },
  { 'viewValue': 'ECUADOR', 'value': 'Z605' },
  { 'viewValue': 'GUYANA', 'value': 'Z606' },
  { 'viewValue': 'GUYANA FRANCESE', 'value': 'Z607' },
  { 'viewValue': 'MALVINE O FALKLAND (ISOLE)', 'value': 'Z609' },
  { 'viewValue': 'PARAGUAY', 'value': 'Z610' },
  { 'viewValue': "PERU'", 'value': 'Z611' },
  { 'viewValue': 'SURINAME', 'value': 'Z608' },
  { 'viewValue': 'TRINIDAD E TOBAGO', 'value': 'Z612' },
  { 'viewValue': 'URUGUAY', 'value': 'Z613' },
  { 'viewValue': 'VENEZUELA', 'value': 'Z614' },
  { 'viewValue': 'AUSTRALIA', 'value': 'Z700' },
  { 'viewValue': 'CAROLINE (ISOLE)=STATI FEDERATI DI MICRONESIA', 'value': 'Z701' },
  { 'viewValue': 'CHRISTMAS (ISOLA)', 'value': 'Z702' },
  { 'viewValue': 'FIGI', 'value': 'Z704' },
  { 'viewValue': 'GILBERT E ELLICE (ISOLE)', 'value': 'Z705' },
  { 'viewValue': 'GUAM (ISOLA)', 'value': 'Z706' },
  { 'viewValue': 'IRIAN OCCIDENTALE', 'value': 'Z707' },
  { 'viewValue': 'ISOLE CILENE (PASQUA E SALA Y GOMEZ)', 'value': 'Z721' },
  { 'viewValue': 'ISOLE COOK', 'value': 'Z703' },
  { 'viewValue': 'ISOLE MARSHALL', 'value': 'Z711' },
  { 'viewValue': 'ISOLE PITCAIRN', 'value': 'Z722' },
  { 'viewValue': 'ISOLE SALOMONE', 'value': 'Z724' },
  { 'viewValue': 'ISOLE WALLIS E FUTUNA', 'value': 'Z729' },
  { 'viewValue': 'KIRIBATI', 'value': 'Z731' },
  { 'viewValue': 'MACQUARIE (ISOLE)', 'value': 'Z708' },
  { 'viewValue': 'MARCUS (ISOLE)', 'value': 'Z709' },
  { 'viewValue': 'MARIANNE (ISOLE)', 'value': 'Z710' },
  { 'viewValue': 'MIDWAY (ISOLE)', 'value': 'Z712' },
  { 'viewValue': 'NAURU', 'value': 'Z713' },
  { 'viewValue': 'NIUE', 'value': 'Z714' },
  { 'viewValue': 'NORFOLK (ISOLE E ISOLE DEL MAR DEI CORALLI)', 'value': 'Z715' },
  { 'viewValue': 'NUOVA CALEDONIA', 'value': 'Z716' },
  { 'viewValue': 'NUOVA GUINEA', 'value': 'Z718' },
  { 'viewValue': 'NUOVA ZELANDA', 'value': 'Z719' },
  { 'viewValue': 'NUOVE EBRIDI (ISOLE CONDOMINIO FRANCO-INGLESE)', 'value': 'Z717' },
  { 'viewValue': 'PALAU', 'value': 'Z734' },
  { 'viewValue': 'PAPUA NUOVA GUINEA', 'value': 'Z730' },
  { 'viewValue': 'PAPUASIA', 'value': 'Z720' },
  { 'viewValue': 'POLINESIA FRANCESE (ISOLE)', 'value': 'Z723' },
  { 'viewValue': 'SAMOA', 'value': 'Z726' },
  { 'viewValue': 'SAMOA AMERICANE (ISOLE)', 'value': 'Z725' },
  { 'viewValue': 'STATI FEDERATI DI MICRONESIA', 'value': 'Z735' },
  { 'viewValue': "TOKELAU O ISOLE DELL'UNIONE", 'value': 'Z727' },
  { 'viewValue': 'TONGA', 'value': 'Z728' },
  { 'viewValue': 'TUVALU', 'value': 'Z732' },
  { 'viewValue': 'VANUATU', 'value': 'Z733' }
];

export const tavola_carattere_di_controllo_valore_caratteri_pari = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  A: 0,
  B: 1,
  C: 2,
  D: 3,
  E: 4,
  F: 5,
  G: 6,
  H: 7,
  I: 8,
  J: 9,
  K: 10,
  L: 11,
  M: 12,
  N: 13,
  O: 14,
  P: 15,
  Q: 16,
  R: 17,
  S: 18,
  T: 19,
  U: 20,
  V: 21,
  W: 22,
  X: 23,
  Y: 24,
  Z: 25
};

export const tavola_carattere_di_controllo = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
