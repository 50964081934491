import { MatPaginatorIntl } from '@angular/material/paginator';
import { Sidebar } from '@components/sidebar/sidebar.interface';
import { SocketIoConfig } from 'ngx-socket-io';
import { environment } from '@env/environment';
import { PermissionList } from './constants';

export const socketIoConfig: SocketIoConfig = { url: environment.PA_MASSIVE_URL, options: { transports: ['websocket'], autoConnect: false } };

export const sidebarConfig: Sidebar[] = [
  {
    title: 'Homepage',
    url: 'homepage',
    icon: 'home_filled',
    permissions: []
  },
  {
    title: 'Catalogo',
    url: 'catalog',
    icon: 'window',
    permissions: []
  },
  {
    title: 'Pre-Check e Vendita',
    url: 'sales',
    icon: 'local_grocery_store',
    permissions: [PermissionList.VENDITA_COMMODITY_BUNDLE]
  },
  {
    title: 'Preventivatore FLAT',
    url: 'flat-offers',
    icon: 'calculate',
    permissions: []
  },
  {
    title: 'Gestione Pratiche Massive',
    url: 'massive-practices',
    icon: 'logout',
    permissions: []
  },
  {
    title: 'Tracking Pratiche',
    url: 'tracking-contracts',
    icon: 'manage_search',
    permissions: []
  },
  {
    title: 'Gestione Pratiche VAS',
    url: 'tracking-contracts-luminea',
    icon: 'find_in_page',
    permissions: []
  },
  {
    title: 'Post Sales',
    url: 'post-sales',
    icon: 'note_alt',
    permissions: []
  },
  {
    title: 'Monitoraggio KPI',
    url: 'kpi-monitoring',
    icon: 'insights',
    permissions: []
  },
  {
    title: 'Tracking Pratiche BOE',
    url: 'tracking-contracts-new/NN',
    icon: 'find_in_page',
    permissions: []
  },
  {
    title: 'Monitoraggio KPI BOE',
    url: 'kpi-boe-monitoring',
    icon: 'monitoring',
    permissions: []
  },
  {
    title: 'Gestione BOE',
    url: 'boe',
    icon: 'ring_volume',
    permissions: []
  },
  {
    title: 'Anagrafica Agenti',
    url: 'agent-profile',
    icon: 'people_alt',
    permissions: []
  },
  {
    title: 'Configura Agenzie',
    url: 'agencies-manager',
    icon: 'store',
    permissions: []
  },
  {
    title: 'Formazione',
    url: 'training',
    icon: 'auto_stories',
    permissions: []
  },
  {
    title: 'Gestione Lead',
    url: 'lead-management',
    icon: 'mark_chat_read',
    permissions: []
  },
  {
    title: 'Campagne Assegnate',
    url: 'assigned-campaign',
    icon: 'campaign',
    permissions: []
  },
  {
    title: 'Instant Call',
    url: 'instant-call',
    icon: 'add_call',
    permissions: []
  },
  {
    title: 'Gestione BOE',
    url: 'boe',
    icon: 'ring_volume',
    permissions: []
  },
  {
    title: 'Configura Agenzie',
    url: 'agencies-manager',
    icon: 'store',
    permissions: []
  },
  {
    title: 'Caricamento Prewinback',
    url: 'prewinback-conf',
    icon: 'assignment_return',
    permissions: []
  },
  {
    title: 'Verifica Copertura',
    url: environment.linkOPNet,
    icon: 'network_check',
    permissions: []
  },
  {
    title: 'Gestisci Recensioni',
    url: 'recensioni',
    icon: 'forum',
    permissions: []
  },
  {
    title: 'Gestisci Clienti',
    url: 'customer-management',
    icon: 'assignment_ind',
    permissions: []
  },
  {
    title: 'Configura Pratiche',
    url: 'practices-management',
    icon: 'description',
    permissions: []
  }
];

export const sidebarBoeConfig: Sidebar[] = [
  {
    title: 'Homepage',
    url: 'homepage-boe',
    icon: 'home_filled',
    permissions: []
  },
  {
    title: 'Gestione pratiche massive',
    url: 'massive-practices-boe',
    icon: 'logout',
    permissions: []
  },
  {
    title: 'Tracking Pratiche',
    url: 'tracking-contracts-new/NN',
    icon: 'find_in_page',
    permissions: []
  },
  {
    title: 'Monitoraggio KPI BOE',
    url: 'kpi-boe-monitoring',
    icon: 'insights',
    permissions: []
  },
  {
    title: 'Anagrafica agenti',
    url: 'agent-profile',
    icon: 'people_alt',
    permissions: []
  },
  {
    title: 'Formazione',
    url: 'training',
    icon: 'auto_stories',
    permissions: []
  }
];

export const sidebarEditorConfig: Sidebar[] = [
  {
    title: 'Caricamento lead freddi',
    url: 'editor/goal-list',
    icon: 'auto_stories',
    permissions: []
  }
];

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Elementi per pagina';

  return customPaginatorIntl;
}
