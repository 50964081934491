import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { clearEmptyKeyFromObject } from '@constants/constants';
import { environment } from '@env/environment';
import { IGenericHttpResponse, ILogin, ILoginResponse } from '@modules/login/login.interface';
import { parseJwt } from '@modules/login/services/login.service';
import { SelectInputType } from '@modules/wizard-sales/store/sales.state';
import { Agency } from '@shared/agency-pick/agency-pick/agency-pick.component';
import { BehaviorSubject, from, map, Observable, Operator, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { getAgencyByMensSelector, getAgencyBySfidSelector } from '@core/store/store.selector';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  public GET_ALL_AGENCY_URL: string = '/agency';
  public CHECK_IBAN_URL: string = '/nds/check-iban';
  public REFRESH_TOKEN: string = '/auth/refresh-token';
  public CHECK_IBAN_HOLDER_URL: string = '/iban/validazione';
  public VERIFY_CAPTCHA_URL: string = '/verify/captcha';
  public GET_AGENT_FROM_AGENCY_URL: string = '/operator/list?cod_agen=';

  public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private angularFire: AngularFireRemoteConfig = this.angularFireRemoteConfig;

  constructor(private http: HttpClient, private angularFireRemoteConfig: AngularFireRemoteConfig, private store: Store) {
    from(this.angularFire.activate()).subscribe();
  }
  getFirebaseConf() {
    return from(this.angularFire.app);
  }
  getAllAgency(filter?: any): Observable<Agency[]> {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.GET_ALL_AGENCY_URL, { params: clearEmptyKeyFromObject(filter || {}) }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'AGENZIE - ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as Agency[]),
      map(res =>
        res.map(el => ({
          accountName: el.accountName,
          accountRecordType: el.accountRecordType,
          canaliVenditaPartner: el.canaliVenditaPartner,
          gruppoLavoroCompetente: el.gruppoLavoroCompetente,
          canale: el.defaultChannel || el.canaliVenditaPartner[0],
          codiceMens: el.codiceMens,
          partitaIVA: el.partitaIVA,
          defaultChannel: el.defaultChannel,
          lumineaEnabled: el.lumineaEnabled || false,
          societa: el.societa,
          id: el.id,
          sfid: el.sfid,
          agencyShortDescription: el.agencyShortDescription,
          configuredIrenForce: el.configuredIrenForce,
          rpoAvailability: el.rpoAvailability,
          enabledInternalCall: el.enabledInternalCall,
          startDate: el.startDate,
          channelsWebSource: el.channelsWebSource,
          isPresent: el.isPresent,
          vulnEleEnabled: el.vulnEleEnabled,
          hotLead: el.hotLead,
          coldLead: el.coldLead,
          uploadFiles: el.uploadFiles,
          tpPermissionEnabled: el.tpPermissionEnabled,
          scoreAgency: el.scoreAgency
        }))
      )
    );
  }

  getOperatorByPermission(agency: string, permission: number): Observable<{ data: SelectInputType[] }> {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.GET_AGENT_FROM_AGENCY_URL + `${agency}`}`, { params: { permission: permission } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'TRAINING- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => ({
        data: res.data as any
      })),
      map(res => ({
        data: res.data.map((res: any) => {
          return {
            value: res.codOperatore,
            viewValue: res.name + ' ' + res.surname + ' ' + res.codOperatore,
            practiceLumineaAssigned: res.practiceLumineaAssigned || '0'
          };
        }) as SelectInputType[]
      }))
    );
  }

  checkIban(iban: string): Observable<string> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PUBLIC_BASE_URL + this.CHECK_IBAN_URL, iban).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'IBAN- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) };
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data),
      map(res => (res.isValid ? 'true' : res.errorMessage))
    );
  }
  refreshToken(refreshToken: string): Observable<Partial<ILogin>> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.REFRESH_TOKEN, { refreshToken: refreshToken }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'TOKEN- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) };
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as ILoginResponse),
      map(res => ({
        mfa: !res.need_configure_mfa,
        tempPassword: res.temp_password || false,
        idToken: res.id_token,
        username: res.username,
        expirationDate: res.expiration_date,
        firstAccess: !!res.need_configure_mfa && !!res.temp_password,
        name: parseJwt(res.id_token).given_name || 'Agente',
        refreshToken: res.refresh_token,
        lockedUser: {
          locked: res.locked_user.locked,
          lockDuration: res.locked_user.lock_duration,
          unlockHour: res.locked_user.unlock_hour
        }
      }))
    );
  }
  checkIbanHolder(ibanHolderData: Partial<CheckIbanHolderRequest>): Observable<string> {
    return this.http.post<CheckIbanResponse>(environment.PA_PRIVATE_BASE_URL + this.CHECK_IBAN_HOLDER_URL, ibanHolderData).pipe(
      tap(response => {
        if (response.status.codice !== '000' && response.status.codice !== '426' && response.status.codice !== '427') {
          const errorMessage = 'IBAN- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) };
          // todo redirect to oops page with error message
        }
      }),
      map(res => (res.status.codice === '000' && res?.data?.ibanCheck?.esito === 'OK' ? 'true' : res.status.codice === '426' || res.status.codice === '427' ? 'ok con riserva' : 'false'))
    );
  }

  verifyRecaptcha(token: string): Observable<ReCaptchaResponse> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PUBLIC_BASE_URL + this.VERIFY_CAPTCHA_URL, { captcha: token }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'RECAPTCHA- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) };
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as ReCaptchaResponse),
      map(res => ({
        success: res.success,
        challengeTs: res.challengeTs,
        hostname: res.hostname,
        errorCodes: res.errorCodes
      }))
    );
  }

  downloadBase64(base64String: string, fileName: string, format: string) {
    const source = `data:${format};base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = fileName;
    link.click();
  }

  getAgencyName(agencyId: string): Observable<Agency | undefined> {
    return this.store.select(getAgencyBySfidSelector(agencyId));
  }

  getAgencyNameByMens(agencyId: string): Observable<Agency | undefined> {
    return this.store.select(getAgencyByMensSelector(agencyId));
  }

  isOmocode(cf: string): boolean {
    // Regex per verificare se ci sono lettere nelle posizioni numeriche che indicano omocodia
    const omocodiaCheck = /[LMNPQRSTUV]/;

    // Controlla le sezioni numeriche che possono subire omocodia
    const dayPart = cf.slice(9, 11);
    const yearPart = cf.slice(6, 8);
    const cadastralCodePart = cf.slice(12, 15);

    return omocodiaCheck.test(dayPart) || omocodiaCheck.test(yearPart) || omocodiaCheck.test(cadastralCodePart);
  }
}

export interface CheckIbanResponse extends IGenericHttpResponse {
  responseStatus: string;
}

export interface CheckIbanHolderRequest {
  account: AccountCheckIban;
  accountHolder: AccountCheckIbanHolder;
  operatoreEsterno: ExternalOperatorCheckIban;
}

export interface AccountCheckIban {
  type: string;
  value: string;
}
export interface AccountCheckIbanHolder {
  fiscalCode: string;
  taxCode: string | null;
  type: string;
  vatCode: string | null;
}

export interface ExternalOperatorCheckIban {
  azienda: string;
  codiceFiscale: string;
  codiceOperatore: string;
  cognome: string;
  nome: string;
}

export interface ReCaptchaResponse {
  success: boolean;
  challengeTs: string;
  hostname: string;
  errorCodes: any[];
}
