import { Role } from '@constants/interfaces';
import { IdentityCard } from '@core/app.interface';
import { Agency } from '@shared/agency-pick/agency-pick/agency-pick.component';

export interface UserLogin {
  username: string;
  password: string;
}
export interface UserOtp {
  otp: string;
  transactionId?: string;
}

export interface Profile {
  agency: Partial<Agency>;
  agencyId: string;
  startValidity?: string;
  email: string;
  operatorId: string;
  authorizationGroup: string;
  name: string;
  tipology: string;
  surname: string;
  fiscalCode: string;
  docIssuingBody: string;
  docType: string;
  docId: string;
  docExpDate: string;
  phone: string;
  username: string;
  password: string;
  document: IdentityCard;
  permissions: PermissionName[];
  profile: Role;
  channel: string;
  sportelli: SportelliUser[];
}

export interface SportelliUser {
  sfidSportello: string;
  nomeSportello: string;
}

export interface Permission {
  id: number;
  permission: PermissionName;
  defaultPermission: boolean;
}

export interface PermissionName {
  id: number;
  permission: string;
  area: string;
}

export interface CreateDailyTask {
  id: number;
  text: string;
  type: string;
  userNotifications: any[];
}

export interface AggregatedTracking {
  commodityClosedLost: number;
  commodityClosedWon: number;
  draft: number;
  vasKo: number;
}

export interface GroupedNotifications {
  [key: string]: { count: number; notifications: Notifications[] };
}

export interface Notifications {
  id: number;
  notification: Notification;
  operatorId: string;
  redirectObject: string;
  visualized: boolean;
}
export interface Notification {
  id: number;
  text: string;
  type: string;
  redirectObject: string;
}

export interface ILogin {
  idToken: string;
  username: string;
  expirationDate: string;
  firstAccess: boolean;
  googleQrCode: string;
  mfaMethod: string;
  mfa: boolean;
  name: string;
  tempPassword: boolean;
  refreshToken: string;
  transactionId: string;
  lockedUser: {
    locked: boolean;
    lockDuration?: string;
    unlockHour?: string;
  };
}

export interface ILoginResponse {
  expiration_date: string;
  id_token: string;
  mfa_method: string;
  need_configure_mfa: true;
  temp_password: true;
  username: string;
  validation_token_id: string;
  firstAccess: boolean;
  refresh_token: string;
  transaction_id: string;
  locked_user: {
    lock_duration: string;
    locked: true;
    unlock_hour: string;
  };
}

export interface IOtpResponse {
  otp: boolean;
}

export interface SetMfaResponse {
  qrCode: string;
  transactionId: string;
  token: ILogin;
}

export interface IGenericHttpResponse<T = any> {
  correlationId?: string;
  data: T;
  status: GenericStatus;
  totalCount?: number;
}

export interface IGenericHttpMassiveResponse {
  status: GenericMassiveStatus;
}
export interface GenericStatus {
  codice: string;
  descrizione: string;
  esito: string;
}

export interface GenericMassiveStatus {
  codice: number;
  descrizione: string;
  esito: string;
  base64: string;
}
