import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: 'form' // Applicherà la direttiva a tutti gli elementi <form>
})
export class DisableAutocompleteDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    // Setta l'attributo autocomplete="off" su ogni form
    this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', 'off');
  }
}
